import React, {useState} from "react";
// import {Route, Routes} from "react-router-dom";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L  from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import DoveDormirePreview from "./DoveDormirePreview";

import 'leaflet/dist/leaflet.css'

import './Maps.css'
import ListButton from "../../UI/ListButton";


require('react-leaflet-markercluster/dist/styles.min.css');

function createIcon(url) {
    return new L.Icon({
        iconUrl: url,
    });
}

const Maps = (props) => {

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [markerId, setMarkerId] = useState();

    const markerClick = (e) => {
        console.log(setSelectedIndex(e.target.options.index))
        setSelectedIndex(e.target.options.index) //get index via custom marker property

        const targetId = e.target.options.id
        setMarkerId(targetId)
        const targetElementInterval = setInterval(() => {
            const targetElemet = document.getElementById(targetId)
            if(targetElemet){
                clearInterval(targetElementInterval)
            }
        },500);

    }

    const filterDoveDormireArray = props.doveDormire.filter( doveDormire => {
        return doveDormire.id === markerId;
    })
    function getMarkerIcon(index) {
        if(index === selectedIndex){
            return createIcon("/assets/marker-selected.svg");
            return createIcon("/assets/marker.svg");

        }
     else{
            return createIcon("/assets/marker.png");
        }

    }
    return(
        <div className='relative'>
            {/*<Routes>*/}

            {/*<Route path="/scopri/doveDormire/maps/*" exact element={*/}
                <ListButton
                    url='/scopri/dove-dormire'
                />
            {/*}>*/}
            {/*</Route>*/}
            {/*</Routes>*/}
            <MapContainer
                center={[37.80964239679445, 12.870139823291876]}
                zoom={13}

                // maxBounds={bounds}
                maxBoundsViscosity={1.0}

                className='w-full h-screen-doveDormire z-0'>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/*<MarkerClusterGroup*/}
                {/*    onClick={(e) => console.log('onClick', e)}*/}
                {/*    maxClusterRadius={50}*/}
                {/*    spiderfyOnMaxZoom={false}*/}
                {/*    showCoverageOnHover={false}*/}
                {/*    polygonOptions={{*/}
                {/*        fillColor: '#ffffff',*/}
                {/*        color: '#f00800',*/}
                {/*        weight: 5,*/}
                {/*        opacity: 1,*/}
                {/*        fillOpacity: 0.8,*/}
                {/*        backgroundColor: "#000000"*/}
                {/*    }}*/}
                {/*>*/}
                    { props.doveDormire.map((doveDormireList, i) => (
                        <Marker
                            key={i}
                            index={i}
                            id={doveDormireList.id}
                            position={[(doveDormireList.latitude === null || doveDormireList.latitude === undefined) ? '' : doveDormireList.latitude, (doveDormireList.longitude === null || doveDormireList.longitude === undefined) ? '' : doveDormireList.longitude]}
                            icon={getMarkerIcon(i)}
                            eventHandlers={{
                                click: markerClick
                        }}
                        >
                        {/*<Link*/}
                        {/*    id={doveDormireList.id}*/}
                        {/*    to={`/scopri/doveDormire/preview/${doveDormireList.id}`}*/}
                        {/*    className='w-full'*/}
                        {/*>*/}
                        {/*</Link>*/}
                            <Popup>
                                <DoveDormirePreview
                                    // key={i}
                                    // title={doveDormireList.title}
                                    // previewId={doveDormireList.previewId}
                                    // body={doveDormireList.body}
                                    // id={doveDormireList.id}
                                    items={filterDoveDormireArray}
                                    // markerId={markerId}
                                />
                            </Popup>
                        </Marker>
                    ))}
                {/*</MarkerClusterGroup>*/}
            </MapContainer>
            {/*{ props.doveDormire.map((doveDormireList, i) => (*/}
            {/*<DoveDormirePreview*/}
            {/*    // key={i}*/}
            {/*    // title={doveDormireList.title}*/}
            {/*    // previewId={doveDormireList.previewId}*/}
            {/*    // body={doveDormireList.body}*/}
            {/*    // id={doveDormireList.id}*/}
            {/*    items={filterDoveDormireArray}*/}
            {/*    // markerId={markerId}*/}
            {/*/>*/}
                {/*// <DoveDormirePreview*/}
                {/*//     key={i}*/}
                {/*//     title={doveDormireList.title}*/}
                {/*//     previewId={(markerId === null || markerId === undefined) ? '' : markerId}*/}
                {/*//     body={doveDormireList.body}*/}
                {/*//     id={doveDormireList.id}*/}
                {/*//     markerId={markerId}*/}
                {/*// />*/}
            {/*))}*/}
        </div>
    )
}

export default Maps
