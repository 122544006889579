import React from "react";
import InEvidenzaItem from "./InEvidenzaItem";
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";

const InEvidenzaList = (props) => {
    return (
        <section className="overflow-hidden text-gray-700 events-container">
            <div className="px-1 py-2 mx-auto">
                <div className="flex -m-1 md:-m-2">
                    {/*<Swiper*/}
                    {/*    slidesPerView={2}*/}
                    {/*    spaceBetween={20}*/}
                    {/*    centeredSlides={true}*/}
                    {/*    grabCursor={true}*/}
                    {/*    className="mySwiper"*/}
                    {/*>*/}
                    <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14">
                        <div className="snap-end scroll-mx-6 shrink-0">
                            <div className="shrink-0 w-3 lg:w-5 md:w-5  sm:w-10 sm:-mr-[2px]"></div>
                        </div>
                        { props.eventsHighlighted.map((inEvidenzaList, i) => (
                            // <SwiperSlide
                            //     key={inEvidenzaList.id}
                            // >
                            <InEvidenzaItem
                                key={i}
                                url={`/novita/eventi/${inEvidenzaList.id}`}
                                title={inEvidenzaList.title}
                                body={inEvidenzaList.body}
                                previewId={inEvidenzaList.previewId}
                                order={i}
                                last={props.eventsHighlighted.length -1}
                            />
                            // </SwiperSlide>
                        ))}
                    {/*</Swiper>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InEvidenzaList;
