import React, {useState} from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";


const GoToDirection = (props) => {

    const [currentPosition, setCurrentPosition] = useState(null);

    useEffect(() => {

    // const clickCurrentPosition = () =>{
        navigator.geolocation.getCurrentPosition(function(position) {
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
            setCurrentPosition([position.coords.latitude, position.coords.longitude])
        });
    // }
    }, [navigator]);

    const { t } = useTranslation()

    // return(
    //
            // <a
            //     onClick={clickCurrentPosition}
                // className='mb-6 lg:fixed lg:bottom-32 lg:left-12 lg:w-28 lg:h-28 lg:rounded-full lg:content-center lg:text-center lg:items-center lg:block inline-flex bg-headerGreen justify-center rounded-md bg-opacity-100 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 z-10'
                // target='_blank'
                // href={`https://www.google.com/maps/dir/?api=1&origin=${currentPosition}&destination=${props.latitude},${props.longitude}`}>
                // <svg className='mt-1 mr-2' id="Icon_map-location-arrow" data-name="Icon map-location-arrow" xmlns="http://www.w3.org/2000/svg" width="19.254" height="18" viewBox="0 0 19.254 18">
                //     <path id="Icon_map-location-arrow-2" data-name="Icon map-location-arrow" d="M13.556,19.581l-2.773-8.275H1.773L19.1,1.581Z" transform="translate(-1.773 -1.581)" fill="#fff"/>
                // </svg>
                // {t("percorso_maps_button")}
            // </a>
    // )

}

export default GoToDirection;
