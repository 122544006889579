import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import BackButtonIcon from "./BackButtonIcon";

const BackButton = (props) => {
    const { t } = useTranslation()

    console.log(window.location)
    return(

        <>
            <Link
                to={props.link}
                className={`${props.className} lg:fixed lg:bottom-32 lg:left-12 lg:w-28 lg:h-28 lg:rounded-full lg:content-center lg:text-center lg:items-center lg:block absolute mt-6 ml-6 inline-flex bg-headerGreen justify-center rounded-md bg-opacity-100 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 z-10`}
            >
                <span className='h-2'>
                    <BackButtonIcon/>
                </span>
                <span className='w-full' >
                {t('go_back')}
                </span>
            </Link>
        </>

    );
}

export default BackButton;
