import React from "react";
import NewsList from "./NewsList";

const News = (props) => {
    return(
        <NewsList
            notizie={props.notizie}
        />
    );
};

export default News;
