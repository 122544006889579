import DateEvent from "../Date";
import React from "react";
import {useTranslation} from "react-i18next";

const Informazioni = (props) => {
    const { t } = useTranslation();
    return(
        <div className='informazioni'>
            {(props.dove === null || props.dove === undefined) ? '' : props.dove.length > 0 &&
                <div className="mt-2 mb-2">
                    <p className='text-gray-900 group flex w-full rounded-md px-2 py-2 text-sm'>
                        <span className='mr-2 h-5 w-5'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.15" height="18.944" viewBox="0 0 17.15 18.944"><g id="Icon_feather-calendar" data-name="Icon feather-calendar" transform="translate(0.5 0.5)"><path id="Tracciato_10549" data-name="Tracciato 10549" d="M6.294,6H18.855A1.794,1.794,0,0,1,20.65,7.794V20.355a1.794,1.794,0,0,1-1.794,1.794H6.294A1.794,1.794,0,0,1,4.5,20.355V7.794A1.794,1.794,0,0,1,6.294,6Z" transform="translate(-4.5 -4.206)" fill="none" stroke="#D1343D" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/><path id="Tracciato_10550" data-name="Tracciato 10550" d="M24,3V6.589" transform="translate(-12.336 -3)" fill="none" stroke="#D1343D" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/><path id="Tracciato_10551" data-name="Tracciato 10551" d="M12,3V6.589" transform="translate(-7.514 -3)" fill="none" stroke="#D1343D" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/><path id="Tracciato_10552" data-name="Tracciato 10552" d="M4.5,15H20.65" transform="translate(-4.5 -7.822)" fill="none" stroke="#D1343D" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/></g></svg>
                        </span>
                        <span className='inline-block'>
                            <span className='block uppercase text-greenText font-medium'>{t("dove")}</span>
                            {props.dove}
                        </span>
                    </p>
                </div>
            }
            <div className="mb-2 mt-2">
                {(props.dateFrom === null || props.dateFrom === undefined) ? '' : props.dateFrom.length > 0 && (props.dateTo === null || props.dateTo === undefined) ? '' : props.dateTo.length > 0 &&
                    <span className='text-gray-900 group flex w-full rounded-md px-2 py-2 text-sm'>
                        <span className='mr-2 h-5 w-5'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20.838" viewBox="0 0 15 20.838"><g id="Icon_ionic-md-pin" data-name="Icon ionic-md-pin" transform="translate(0.5 0.5)"><path id="Icon_ionic-md-pin-2" data-name="Icon ionic-md-pin" d="M13.75,2.25a6.951,6.951,0,0,0-7,6.86c0,5.145,7,12.74,7,12.74s7-7.6,7-12.74A6.951,6.951,0,0,0,13.75,2.25Zm0,9.31a2.451,2.451,0,1,1,2.5-2.45A2.452,2.452,0,0,1,13.75,11.56Z" transform="translate(-6.75 -2.25)" fill="none" stroke="#D1343D" stroke-width="1"/></g></svg>
                        </span>
                        <span className='block'>
                            <span className='block uppercase text-greenText font-medium' >{t("quando")}</span>
                                  <DateEvent
                                      dateFrom={props.dateFrom}
                                      dateTo={props.dateTo}
                                  >
                                {props.children}
                            </DateEvent>


                        </span>
                    </span>
                }
                {(props.prezzi === null || props.prezzi === undefined) ? '' : props.prezzi.length > 0 &&
                    <p className='text-gray-900 group flex w-full rounded-md px-2 py-2 text-sm'>
                        <span className='mr-2 h-5 w-5'>
                            <svg id="Icon_material-euro-symbol" data-name="Icon material-euro-symbol" xmlns="http://www.w3.org/2000/svg" width="16.15" height="16.15" viewBox="0 0 16.15 16.15"><path id="Icon_material-euro-symbol-2" data-name="Icon material-euro-symbol" d="M15.267,18.407a5.814,5.814,0,0,1-5.168-3.14h5.168V13.472H9.506a5.643,5.643,0,0,1,0-1.794h5.76V9.883H10.1a5.82,5.82,0,0,1,8.963-1.732L20.65,6.564A8.063,8.063,0,0,0,7.658,9.883H4.5v1.794H7.245a7.5,7.5,0,0,0,0,1.794H4.5v1.794H7.658a8.063,8.063,0,0,0,12.992,3.32L19.053,17a5.747,5.747,0,0,1-3.786,1.409Z" transform="translate(-4.5 -4.5)" fill="#D1343D"/></svg>
                        </span>

                        <span className='block'>
                            <span className='block uppercase text-greenText font-medium'>{t("prezzo")}</span>
                            <span className='lg:text-base'>{props.prezzi}</span>
                        </span>
                    </p>
                }
            </div>
        </div>
    );
}

export default Informazioni
