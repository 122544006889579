import {NavLink} from "react-router-dom";
import React from "react";
import './FilterScopri.css'

const FilterScopri = (props) => {
    return(
        <nav className='scopri-filter text-center w-full h-28 shadow-2xl z-50 lg:fixed lg:right-0 lg:h-auto lg:w-28 lg:rounded-full lg:mr-12 centrato lg:bg-bgNewGrey'>
            <ul className='flex justify-center space-x-4 pb-6 flex-wrap content-center lg:space-x-0 lg:block lg:text-center lg:pt-4 lg:pb-4 pt-0 pb-0'>
                { props.data.map((scopriMenuFilter, index) => (
                    <li key={index} className='menu__item w-1/5 lg:w-9/12 lg:m-auto text-center h-28 lg:h-32 text-sm p-0 '>
                        <NavLink
                            className={(navData) => navData.isActive ? 'outline-0 active shadow-md border-0 bg-bgNewGrey lg:bg-white lg:pt-4 lg:pb-4 text-white lg:text-white h-28 lg:32 ring-opacity-0 shadow-none flex flex-wrap items-center justify-center text-slate-900 transition-all duration-500 demo-dark:bg-slate-100 transition-all duration-500 demo-dark:text-slate-700 flex-none mx-auto' : 'lg:text-white h-28 lg:pt-4 lg:pb-4 ring-opacity-0 shadow-none flex flex-wrap items-center justify-center text-slate-900 transition-all duration-500 demo-dark:bg-slate-100 transition-all duration-500 demo-dark:text-slate-700 flex-none mx-auto'}
                            to={`/scopri${scopriMenuFilter.relative}`}
                        >
                            <span
                                className='icon icon-regular'
                                dangerouslySetInnerHTML={{ __html: scopriMenuFilter.icon_regular}}
                            />
                            <span
                                className='icon icon-active'
                                dangerouslySetInnerHTML={{ __html: scopriMenuFilter.icon_active}}
                            />
                            <span className='text w-full'>
                                {scopriMenuFilter.title}
                            </span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default FilterScopri
