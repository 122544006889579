import MapButton from "../../UI/MapButton";
import ServiziItem from "./ServiziItem";
import React from "react";
import {useTranslation} from "react-i18next";

const ServiziListContainer = (props) => {
    const { t } = useTranslation();

    return(
        <div className='servizi-list px-8 mt-3' >
            <div className='block w-100 relative mb-5'>
                <h2 className='pb-4 text-greenText font-normal inline-block text-left pr-20 mt-2' style={{lineHeight: "34px"}}>{t('section_servizi_title')}</h2>
                <MapButton
                    url='/scopri/servizi'
                />
            </div>
            <div className='servizi-items w-100 block'>
                { props.items.servizi.map((serviziList, i) => (
                    <ServiziItem
                        key={serviziList.id}
                        url={`/scopri/servizi/list/${serviziList.id}`}
                        title={serviziList.title}
                        body={serviziList.body}
                        previewId={serviziList.previewId}
                    />
                ))}
            </div>
        </div>
    )
}

export default ServiziListContainer