const MapButtonIcon = () => {
    return(
        <svg className='lg:w-10 lg:h-10 lg:mb-1 lg:text-center lg:m-auto' id="map" xmlns="http://www.w3.org/2000/svg" width="17.874" height="17.875" viewBox="0 0 17.874 17.875">
            <g id="Raggruppa_718" data-name="Raggruppa 718" transform="translate(0 4.468)">
                <g id="Raggruppa_717" data-name="Raggruppa 717">
                    <path id="Tracciato_10573" data-name="Tracciato 10573" d="M17.332,131.351a.559.559,0,0,0-.559.559v6.325L12.3,140.023v-5.878a.559.559,0,1,0-1.117,0v5.877l-4.469-1.787v-8.85l2.588,1.036a.559.559,0,0,0,.414-1.038l-3.344-1.338h0l-.009,0a.554.554,0,0,0-.414,0l-.01,0h0L.367,130.274a.559.559,0,0,0-.351.518v10.054a.558.558,0,0,0,.766.518l5.378-2.15,5.368,2.147h0l.01,0a.554.554,0,0,0,.414,0l.01,0h0l5.576-2.23a.562.562,0,0,0,.35-.519v-6.7A.559.559,0,0,0,17.332,131.351ZM5.6,138.234l-4.469,1.787V131.17L5.6,129.383Z" transform="translate(-0.016 -128)" fill="#fefefe"/>
                </g>
            </g>
            <g id="Raggruppa_720" data-name="Raggruppa 720" transform="translate(12.289 2.234)">
                <g id="Raggruppa_719" data-name="Raggruppa 719">
                    <path id="Tracciato_10574" data-name="Tracciato 10574" d="M353.692,64a1.676,1.676,0,1,0,1.676,1.676A1.678,1.678,0,0,0,353.692,64Zm0,2.234a.559.559,0,1,1,.559-.559A.559.559,0,0,1,353.692,66.234Z" transform="translate(-352.016 -64)" fill="#fefefe"/>
                </g>
            </g>
            <g id="Raggruppa_722" data-name="Raggruppa 722" transform="translate(10.054)">
                <g id="Raggruppa_721" data-name="Raggruppa 721">
                    <path id="Tracciato_10575" data-name="Tracciato 10575" d="M291.926,0a3.914,3.914,0,0,0-3.91,3.91c0,2.006,3.137,5.56,3.494,5.959a.558.558,0,0,0,.831,0c.357-.4,3.494-3.952,3.494-5.959A3.914,3.914,0,0,0,291.926,0Zm0,8.643c-1.22-1.447-2.793-3.668-2.793-4.733a2.793,2.793,0,0,1,5.586,0C294.719,4.975,293.146,7.2,291.926,8.643Z" transform="translate(-288.016 0)" fill="#fefefe"/>
                </g>
            </g>
        </svg>
    );
}

export default MapButtonIcon
