import React from "react";
import EventsList from "./EventsList";

const Events = (props) => {
    return(
        <EventsList
            events={props.events}
        />
    );
};

export default Events;
