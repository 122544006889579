import React from "react";
import EventsItem from "./EventsItem";
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";

const EventsList = (props) => {
    return (
        // <section className="overflow-hidden text-gray-700 events-container -ml-24">
        <section className="overflow-hidden text-gray-700 events-container">
            <div className=" px-1 py-2 mx-auto ">
                <div className="flex -m-1 md:-m-2">
                    {/*<Swiper*/}
                    {/*    slidesPerView={2}*/}
                    {/*    spaceBetween={20}*/}
                    {/*    centeredSlides={true}*/}
                    {/*    grabCursor={true}*/}
                    {/*    className="mySwiper"*/}
                    {/*>*/}
                    <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14">
                        <div className="snap-end scroll-mx-6 shrink-0">
                            <div className="shrink-0 w-3 lg:w-5 md:w-5  sm:w-10 sm:-mr-[2px]"></div>
                        </div>
                        { props.events.map((eventList, i) => (
                            // <SwiperSlide
                            //     key={eventList.id}
                            // >

                            <EventsItem
                                key={i}
                                url={`/novita/eventi/${eventList.id}`}
                                title={eventList.title}
                                body={eventList.body}
                                previewId={eventList.previewId}
                                order={i}
                                last={props.events.length -1}

                            />
                            // </SwiperSlide>
                        ))}
                    </div>
                    {/*</Swiper>*/}

                </div>
            </div>
        </section>
    );
}

export default EventsList;
