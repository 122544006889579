import ServiziDetailTagListSecondaria from "./ServiziDetailTagListSecondaria";

const ServiziDetailTagSecondaria = (props) => {

    return(
        <div>

            {props.tag &&

                props.tag.map((tag, i) => (
                <ServiziDetailTagListSecondaria
                    key={i}
                    tag={tag}
                />
            ))
            }

        </div>
    );}
export default ServiziDetailTagSecondaria;
