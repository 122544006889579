import React, { useEffect, useState } from "react";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    Circle,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "../../UI/currentPositionMarker";
import API from "../../api";
import OperePreview from "./OperePreview";
import ListButton from "../../UI/ListButton";

function LocationMarker() {
    const [position, setPosition] = useState(null);
    const [percorsi, setPercorsi] = useState([]);

    useEffect(() => {
        async function fetchPercorsiHandler() {
            const currentLanguageCode = localStorage.getItem("i18nextLng");
            const response = await API.get(
                `/${
                    currentLanguageCode === undefined || currentLanguageCode === null
                        ? "it"
                        : currentLanguageCode
                }/jsonapi/node/opere?include=field_immagine_anteprima.field_media_image`
            );
            const dataPercorsi = await response.data;

            if (dataPercorsi) {
                const transformedPercorsi = dataPercorsi.data.map((percorsiData) => {
                    return {
                        id: percorsiData.id,
                        title: percorsiData.attributes.title,
                        body: percorsiData.attributes.body,
                        latitude: percorsiData.attributes.field_latitudine,
                        longitude: percorsiData.attributes.field_longitudine,
                        previewId: percorsiData.relationships.field_immagine_anteprima.data.id,
                    };
                });
                setPercorsi(transformedPercorsi);
            }
        }

        fetchPercorsiHandler();
    }, []);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setPosition([position.coords.latitude, position.coords.longitude]);
        });
    }, []);

    return position === null ? null : (
        <>
            {percorsi.map((markerList, i) => (
                <Marker
                    key={i}
                    index={i}
                    position={[markerList.latitude || 0, markerList.longitude || 0]}
                >
                    <Popup>
                        <OperePreview items={markerList} />
                    </Popup>
                </Marker>
            ))}
            {/*<Circle center={position} radius={5000} />*/}
            {/*<Marker position={position} icon={icon}></Marker>*/}
        </>
    );
}

const PercorsiList = () => {
    return (
        <>
            <section className="overflow-hidden text-gray-700 events-container">
                <div className="relative">
                    <ListButton url="/percorsi" />
                    <MapContainer
                        center={[37.80964239679445, 12.870139823291876]}
                        zoom={16}
                        style={{ height: "calc(100vh - 180px)", zIndex: "0" }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker />
                    </MapContainer>
                </div>
            </section>
        </>
    );
};

export default PercorsiList;
