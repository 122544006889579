// import ServiziItem from "./ServiziItem";
import React from "react";
import {Route, Routes} from "react-router-dom";
// import {Refreshable} from 'react-router-refreshable';
import Maps from "./Maps";
// import {useTranslation} from "react-i18next";
// import MapButton from "../../UI/MapButton";
import ServiziListContainer from "./ServiziListContainer";


const ServiziList = (props) =>{

    // const { t } = useTranslation();

    return(
        <section className="overflow-hidden text-gray-700 events-container">
                <Routes>
                    <Route path="list" exact={true} element={
                        <ServiziListContainer
                            items={props}
                        />
                    }>
                    </Route>
                    <Route path="maps" exact={true} element={
                            <Maps
                                className='h-screen'
                                servizi={props.servizi}
                            />
                    }/>
                </Routes>
        </section>
    )
}

export default ServiziList;
