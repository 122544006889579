import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import React from "react";
import ListButtonIcon from "./ListButtonIcon";

const ListButton = (props) => {
    const { t } = useTranslation();

    return(
        <Link to={`${props.url}/list`} className='absolute z-50 right-4 top-6 mt-0.5 inline-block float-right rounded'>
            <button
                className=" lg:fixed lg:bottom-32 lg:right-12 lg:w-28 lg:h-28 lg:rounded-full lg:content-center lg:text-center lg:items-center lg:block inline-flex bg-orange w-full justify-center rounded-md bg-opacity-100 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
                <span className='h-2 mr-2 mt-1 lg:mr-0 lg:mb-2'><ListButtonIcon/></span>
                <span className='w-full' >{t('list_button')}</span>
            </button>
        </Link>
    );
}

export default ListButton
