import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import cookies from "js-cookie";

import API from '../../api'
import Spinner from "../../UI/Spinner";


const OpereItem = (props) => {
    const [isLastElement, setIsLastElement] = useState(false);

    const [opereImage, setOpereImage] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    // const [error, setError] = useState(null)

    useEffect(() => {
        // const currentLanguageInterval = setInterval(() => {
            const currentLanguageCode = localStorage.getItem('i18nextLng');
            // console.log(currentLanguageCode)

            // if(currentLanguageCode) {
            //     clearInterval(currentLanguageInterval)


                if (props.order === props.last) {
                    setIsLastElement(true)
                }

                async function fetchOpereImageHandler() {
                    setIsLoading(true);
                    const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/media/image/${props.previewId}?include=field_media_image`)
                    const dataOpereImage = await response.data;

                    if (dataOpereImage) {
                        const transformedOpereImage = dataOpereImage.included.map(opereImageData => {
                            return {
                                previewImage: opereImageData.attributes.uri.url
                            };
                        });
                        setOpereImage(transformedOpereImage);
                        setIsLoading(false);
                    }


                    return false
                }

                const myPromise = new Promise((resolve, reject) => {
                    resolve('foo');
                });

                myPromise
                    .then(fetchOpereImageHandler)

            // }
        // },100);


    }, [props.previewId]);
    return (
        <div className={`snap-end scroll-mx-6 shrink-0 w-72 ${isLastElement ? 'mr-8' : '' }`}
        >
            <div className="flex" key={props.id}>
                <Link to={{pathname: props.url, state:{ prevPath: 'Homepage' }}} className='w-full'>
                    <div className={`relative overflow-hidden shadow-lg cursor-pointer shadow-up ${props.imgRounded}`}>
                        {!isLoading && opereImage.length > 0 &&
                            <img className="object-cover w-full h-48 rounded-lg"
                                 src={`https://gibellina.bbsitalia.com/${opereImage[0].previewImage}`}
                                 alt="Flower and sky"/>
                        }
                        {isLoading && <Spinner className='flex items-center justify-center w-full top-0 text-center' />}

                        <div className="absolute bottom-0 left-0 px-6 py-4">
                            <h4 className="mb-3 text-l font-normal tracking-tight text-white">
                                {(props.title === null || props.title === undefined) ? '' : props.title}
                            </h4>

                            {/*<p className="leading-normal text-gray-100">Lorem ipsum dolor, sit amet cons ectetur adipis icing elit.*/}
                            {/*    Praesen tium, quibusdam facere quo laborum maiores sequi nam tenetur laud.</p>*/}
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default OpereItem;
