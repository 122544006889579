import MuseiList from "./MuseiList";
import React, {useEffect, useState} from "react";
import cookies from "js-cookie";

import API from '../../api'
import Spinner from "../../UI/Spinner";


const Musei = () =>{
    const [musei, setMusei] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchMuseiHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')
            setIsLoading(true);
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/musei?include=field_immagine_anteprima.field_media_image`)
            const dataMusei = await response.data;
            console.log(dataMusei)
            if(dataMusei){
                const transformedMusei = dataMusei.data.map(museiData => {
                    return{
                        id: museiData.id,
                        title: museiData.attributes.title,
                        body: museiData.attributes.body,
                        latitude: museiData.attributes.field_latitudine,
                        longitude: museiData.attributes.field_longitudine,
                        previewId: museiData.relationships.field_immagine_anteprima.data.id
                    };
                });
                setMusei(transformedMusei);
                setIsLoading(false);
            }
            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(fetchMuseiHandler)
    }, []);
    return(
        <section className='w-full lg:w-10/12'>
            {!isLoading && musei.length > 0 &&
                <div className='musei'>
                    <MuseiList
                        musei={musei}
                    />
                </div>
            }
            {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            {!isLoading && musei.length === 0 && <p>No musei data</p>}
        </section>
    )
}

export default Musei;
