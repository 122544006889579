import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import Gallery from "../../UI/gallery/Gallery";
import cookies from "js-cookie";
import Informazioni from "../UI/Informazioni";
import CardImgHeader from "../UI/CardImgHeader";
import {useTranslation} from "react-i18next";

import API from '../../api'
import BackButton from "../../UI/BackButton";
import Reader from "../eventi/Reader";
import Spinner from "../../UI/Spinner";
import GoToDirection from "../../UI/GoToDirection";


const NewsDetail = (props) => {
    const { t } = useTranslation();

    const params = useParams()
    const [notizia, setNotizia] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        const currentLanguageCode = localStorage.getItem('i18nextLng')
        async function fetchNotiziaHandler() {

                setIsLoading(true);
                let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/news/${params.newsId}?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image`)

                const dataNotizia = await response.data;
                const dataNotiziaArray = [dataNotizia]


                const transformedNotizia = dataNotiziaArray.map(notiziaData => {
                    return{
                        id: notiziaData.data.id,
                        title: notiziaData.data.attributes.title,
                        body: notiziaData.data.attributes.body,
                        date_start: notiziaData.data.attributes.field_data_inizio,
                        date_end: notiziaData.data.attributes.field_data_fine,
                        preview: dataNotizia.included[1].attributes.uri.url,
                        galleria: dataNotizia.included,
                        audio: dataNotizia.included,
                        contatti: notiziaData.data.attributes.field_contatti

                    };
                });
                setNotizia(transformedNotizia);
                setIsLoading(false);


        }
        fetchNotiziaHandler()



    }, [params.newsId]);

    return(
        <div>
            <section>
                <BackButton
                    link={'/novita'}
                />
                {!isLoading && notizia.length > 0 &&
                    <div key={notizia.id}>
                        <CardImgHeader
                            className='relative'
                            preview={notizia[0].preview}
                            title={notizia[0].title}
                            category={t('evento_category')}
                            isLoading={isLoading}
                            data={notizia[0]}
                        />

                        <div className='content p-8 lg:px-24 break-words'>
                            <GoToDirection
                                latitude={notizia[0].latitude}
                                longitude={notizia[0].longitude}
                            />
                            <Reader
                                value={[notizia[0].title.replace(/<[^>]*>?/gm, ''), (notizia[0].body === null || notizia[0].body === undefined) ? '' : notizia[0].body.processed.replace(/<[^>]*>?/gm, '')]}
                                body={(notizia[0].body === null || notizia[0].body === undefined) ? '' : notizia[0].body.processed.replace(/<[^>]*>?/gm, '')}
                                audio={notizia[0].audio}
                            />

                            <Informazioni
                                dove={notizia[0].dove}
                                dateFrom={(notizia[0].date_start === null || notizia[0].date_start === undefined) ? '' : notizia[0].date_start}
                                dateTo={(notizia[0].date_end === null || notizia[0].date_end === undefined) ? '' : notizia[0].date_end}
                                prezzi={notizia[0].prezzi}
                            />

                            {notizia[0].body &&

                                <div className='description mt-3'>
                                <span className='font-medium mb-3 block text-xl'>{t('descrizione')}</span>
                                {(notizia[0].body === null || notizia[0].body === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: notizia[0].body.processed}} />}
                            </div>
                            }
                            {notizia[0].contatti &&

                                <div className='description mt-3'>
                                    <span className='font-medium mb-3 block text-xl'>{t('contatti')}</span>
                                    {(notizia[0].contatti === null || notizia[0].contatti === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: notizia[0].contatti.processed}} />}


                                </div>
                            }
                        </div>

                            <div className='galleria'>
                                {/*<section className="overflow-hidden text-gray-700 notizia-container -ml-28 mt-8 mb-9">*/}
                                <section className="overflow-hidden text-gray-700 notizia-container mt-8 mb-9">
                                    {/*<div className="container px-1 py-2 lg:pt-12 lg:px-32 ">*/}
                                    <div className="px-1 py-2 w-full ">
                                        <div className="flex -m-1 md:-m-2">
                                            <Gallery
                                                gallery={notizia[0].galleria}
                                            >
                                                {props.children}
                                            </Gallery>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        {(notizia[0].latitude === null || notizia[0].latitude === undefined) ? '' : notizia[0].latitude > 0 && (notizia[0].longitude === null || notizia[0].longitude === undefined) ? '' : notizia[0].longitude  > 0 &&

                            <MapContainer
                                center={[(notizia[0].latitude === null || notizia[0].latitude === undefined) ? '' : notizia[0].latitude, (notizia[0].longitude === null || notizia[0].longitude === undefined) ? '' : notizia[0].longitude]}
                                zoom={13}
                                scrollWheelZoom={false}
                                className='w-full h-72 lg:h-96 z-0'
                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[(notizia[0].latitude === null || notizia[0].latitude === undefined) ? '' : notizia[0].latitude, (notizia[0].longitude === null || notizia[0].longitude === undefined) ? '' : notizia[0].longitude]}>
                                    {/*<Popup>*/}
                                    {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                    {/*</Popup>*/}
                                </Marker>
                            </MapContainer>
                        }
                    </div>
                }
                {!isLoading && notizia.length === 0 && <p>No notizia data</p>}
                {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
                </section>
        </div>
    );}

export default NewsDetail;
