import React, { useState, useRef, useEffect } from 'react'
import styles from "./AudioPlayer.module.css";

const Speech = (props) => {

    //
    // const [isAudio, setAudio] = useState()
    // useEffect(() => {
    //
    //     // console.log(props.value)
    //     // console.log(props)
    //
    //
    //     const arrayTest = props.audio
    //
    //     if(arrayTest){
    //
    //         const arrayFilter = arrayTest.filter(test => test.type.includes('file--file') )
    //         const arrayFilterAudio = arrayFilter.filter(test => test.attributes.filemime.includes('audio/mpeg'))
    //         // console.log('Filtro audio/mpeg', arrayFilterAudio)
    //         if(arrayFilterAudio[0]){
    //             const audioText = `https://gibellina.bbsitalia.com${arrayFilterAudio[0].attributes.uri.url}`;
    //             setAudio(audioText)
    //
    //         }
    //
    //     }
    //
    // }, []);
    //
    // // state
    // const [isPlaying, setIsPlaying] = useState(false);
    // const [duration, setDuration] = useState(0);
    // const [currentTime, setCurrentTime] = useState(0);
    //
    // // references
    // const audioPlayer = useRef();   // reference our audio component
    // const progressBar = useRef();   // reference our progress bar
    // const animationRef = useRef();  // reference the animation
    //
    // useEffect(() => {
    //     const seconds = Math.floor(audioPlayer.current.duration);
    //     setDuration(seconds);
    //     progressBar.current.max = seconds;
    // }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);
    //
    // const calculateTime = (secs) => {
    //     const minutes = Math.floor(secs / 60);
    //     const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    //     const seconds = Math.floor(secs % 60);
    //     const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    //     return `${returnedMinutes}:${returnedSeconds}`;
    // }
    //
    // const togglePlayPause = () => {
    //     const prevValue = isPlaying;
    //     setIsPlaying(!prevValue);
    //     if (!prevValue) {
    //         audioPlayer.current.play();
    //         animationRef.current = requestAnimationFrame(whilePlaying)
    //     } else {
    //         audioPlayer.current.pause();
    //         cancelAnimationFrame(animationRef.current);
    //     }
    // }
    //
    // const whilePlaying = () => {
    //     progressBar.current.value = audioPlayer.current.currentTime;
    //     changePlayerCurrentTime();
    //     animationRef.current = requestAnimationFrame(whilePlaying);
    // }
    //
    // const changeRange = () => {
    //     audioPlayer.current.currentTime = progressBar.current.value;
    //     changePlayerCurrentTime();
    // }
    //
    // const changePlayerCurrentTime = () => {
    //     progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
    //     setCurrentTime(progressBar.current.value);
    // }
    //
    // const backThirty = () => {
    //     progressBar.current.value = Number(progressBar.current.value - 10);
    //     changeRange();
    // }
    //
    // const forwardThirty = () => {
    //     progressBar.current.value = Number(progressBar.current.value + 10);
    //     changeRange();
    // }

    return (
        <>
            {/*// <div className="group p-5 text-center rounded-xl" style={{backgroundColor:'rgba(85,85,85,0.08)'}}>*/}
            {/*<span className='block text-black font-medium mb-3'>Ascolta testo pagina</span>*/}

            {/*<audio ref={audioPlayer} src={isAudio} preload="metadata"></audio>*/}
            {/*<button className='inline-block align-middle mr-5' onClick={backThirty}>*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="22.787" height="19.294" viewBox="0 0 22.787 19.294">*/}
            {/*        <g id="Raggruppa_699" data-name="Raggruppa 699" transform="translate(-5383.787 3005)">*/}
            {/*            <g id="forward" transform="translate(5390.574 -3005)">*/}
            {/*                <path id="Tracciato_10555" data-name="Tracciato 10555" d="M15.939,1.854a.5.5,0,0,1,.854.354V9.793a.5.5,0,0,1-.854.354L12.146,6.354a.5.5,0,0,1,0-.707Z" transform="translate(-8.793 -1.706)" fill="#D1343D" fill-rule="evenodd"/>*/}
            {/*                <path id="Tracciato_10556" data-name="Tracciato 10556" d="M11,6a1,1,0,0,1,1-1,8,8,0,1,1-8,8,1,1,0,1,1,2,0,6,6,0,1,0,6-6A1,1,0,0,1,11,6Z" transform="translate(-4 -1.706)" fill="#D1343D" fill-rule="evenodd"/>*/}
            {/*            </g>*/}
            {/*            <text id="_10_" data-name="10&apos;" transform="translate(5383.787 -3005)" fill="#D1343D" font-size="6" font-family="Quicksand-Bold, Quicksand" font-weight="700"><tspan x="0" y="6">10&apos;</tspan></text>*/}
            {/*        </g>*/}
            {/*    </svg>*/}

            {/*</button>*/}
            {/*<button className='inline-block align-middle' onClick={togglePlayPause}>*/}
            {/*    {isPlaying ? <svg id="Componente_55_11" data-name="Componente 55 – 11" xmlns="http://www.w3.org/2000/svg" width="39.156" height="30" viewBox="0 0 39.156 30">*/}
            {/*            <g id="Tracciato_10545" data-name="Tracciato 10545" fill="none">*/}
            {/*                <path d="M9.031,0H30.125c4.988,0,9.031,4.477,9.031,10V20c0,5.523-4.043,10-9.031,10H9.031C4.043,30,0,25.523,0,20V10C0,4.477,4.043,0,9.031,0Z" stroke="none"/>*/}
            {/*                <path d="M 9.031339645385742 1 C 4.602840423583984 1 1 5.037380218505859 1 10 L 1 20 C 1 24.96261978149414 4.602840423583984 29 9.031339645385742 29 L 30.12451171875 29 C 34.55300903320312 29 38.15586090087891 24.96261978149414 38.15586090087891 20 L 38.15586090087891 10 C 38.15586090087891 5.037380218505859 34.55300903320312 1 30.12451171875 1 L 9.031339645385742 1 M 9.031339645385742 0 L 30.12451171875 0 C 35.11238861083984 0 39.15586090087891 4.477149963378906 39.15586090087891 10 L 39.15586090087891 20 C 39.15586090087891 25.52285003662109 35.11238861083984 30 30.12451171875 30 L 9.031339645385742 30 C 4.043472290039062 30 0 25.52285003662109 0 20 L 0 10 C 0 4.477149963378906 4.043472290039062 0 9.031339645385742 0 Z" stroke="none" fill="#D1343D"/>*/}
            {/*            </g>*/}
            {/*            <g id="Icon_material-pause" data-name="Icon material-pause" transform="translate(13 7.125)">*/}
            {/*                <path id="Icon_material-pause-2" data-name="Icon material-pause" d="M9,23.292h4.464V7.5H9ZM17.929,7.5V23.292h4.464V7.5Z" transform="translate(-9 -7.5)" fill="#D1343D"/>*/}
            {/*            </g>*/}
            {/*        </svg>*/}
            {/*        :*/}

            {/*        <svg id="Componente_55_12" data-name="Componente 55 – 12" xmlns="http://www.w3.org/2000/svg" width="40.489" height="30" viewBox="0 0 40.489 30">*/}
            {/*            <g id="Tracciato_10545" data-name="Tracciato 10545" fill="none">*/}
            {/*                <path d="M9.031,0H31.458c4.988,0,9.031,4.477,9.031,10V20c0,5.523-4.043,10-9.031,10H9.031C4.043,30,0,25.523,0,20V10C0,4.477,4.043,0,9.031,0Z" stroke="none"/>*/}
            {/*                <path d="M 9.031343460083008 1 C 4.60284423828125 1 1.000003814697266 5.037380218505859 1.000003814697266 10 L 1.000003814697266 20 C 1.000003814697266 24.96261978149414 4.60284423828125 29 9.031343460083008 29 L 31.45751571655273 29 C 35.88601303100586 29 39.48886489868164 24.96261978149414 39.48886489868164 20 L 39.48886489868164 10 C 39.48886489868164 5.037380218505859 35.88601303100586 1 31.45751571655273 1 L 9.031343460083008 1 M 9.031343460083008 0 L 31.45751571655273 0 C 36.44539642333984 0 40.48886489868164 4.477149963378906 40.48886489868164 10 L 40.48886489868164 20 C 40.48886489868164 25.52285003662109 36.44539642333984 30 31.45751571655273 30 L 9.031343460083008 30 C 4.043476104736328 30 3.814697265625e-06 25.52285003662109 3.814697265625e-06 20 L 3.814697265625e-06 10 C 3.814697265625e-06 4.477149963378906 4.043476104736328 0 9.031343460083008 0 Z" stroke="none" fill="#D1343D"/>*/}
            {/*            </g>*/}
            {/*            <g id="Icon_ionic-ios-play-circle" data-name="Icon ionic-ios-play-circle" transform="translate(11 6)">*/}
            {/*                <path id="Icon_ionic-ios-play-circle-2" data-name="Icon ionic-ios-play-circle" d="M12,3.375A8.625,8.625,0,1,0,20.625,12,8.624,8.624,0,0,0,12,3.375Zm3.475,8.787L9.786,15.6a.184.184,0,0,1-.278-.162V8.558A.184.184,0,0,1,9.786,8.4l5.689,3.442A.19.19,0,0,1,15.475,12.162Z" transform="translate(-3.375 -3.375)" fill="#D1343D"/>*/}
            {/*            </g>*/}
            {/*        </svg>*/}

            {/*    }*/}
            {/*</button>*/}
            {/*<button className='inline-block align-middle ml-5' onClick={forwardThirty}>*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="19.294" viewBox="0 0 29 19.294">*/}
            {/*        <g id="Raggruppa_698" data-name="Raggruppa 698" transform="translate(-5421.787 3005)">*/}
            {/*            <g id="forward" transform="translate(5417.787 -3006.706)">*/}
            {/*                <path id="Tracciato_10555" data-name="Tracciato 10555" d="M12.854,1.854A.5.5,0,0,0,12,2.207V9.793a.5.5,0,0,0,.854.354l3.793-3.793a.5.5,0,0,0,0-.707Z" fill="#D1343D" fill-rule="evenodd"/>*/}
            {/*                <path id="Tracciato_10556" data-name="Tracciato 10556" d="M13,6a1,1,0,0,0-1-1,8,8,0,1,0,8,8,1,1,0,0,0-2,0,6,6,0,1,1-6-6A1,1,0,0,0,13,6Z" fill="#D1343D" fill-rule="evenodd"/>*/}
            {/*            </g>*/}
            {/*            <text id="_10_" data-name="10&apos;" transform="translate(5437.787 -3005)" fill="#D1343D" font-size="6" font-family="Quicksand-Bold, Quicksand" font-weight="700"><tspan x="0" y="6">10&apos;</tspan></text>*/}
            {/*        </g>*/}
            {/*    </svg>*/}

            {/*</button>*/}

            {/*/!* progress bar *!/*/}
            {/*<div>*/}
            {/*    <input type="range" className={styles.progressBar} defaultValue="0" ref={progressBar} onChange={changeRange} />*/}
            {/*</div>*/}


            {/*/!* current time *!/*/}
            {/*/!*<div >{calculateTime(currentTime)}</div>*!/*/}

            {/*/!* duration *!/*/}
            {/*/!*<div >{(duration && !isNaN(duration)) && calculateTime(duration)}</div>*!/*/}
            {/*// </div>*/}
        </>

    )
}

export default Speech
