import MapButton from "../../UI/MapButton";
import Opere2Item from "./Opere2Item";
import React from "react";
import {useTranslation} from "react-i18next";

const Opere2ListContainer = (props) => {
    const { t } = useTranslation();

    return(
        <div className='opere2-list px-8 mt-3' >
            <div className='block w-100 relative mb-5'>
                <h2 className='pb-4 text-greenText font-normal inline-block text-left pr-20 mt-2' style={{lineHeight: "34px"}}>{t('section_opere2_title')}</h2>
                <MapButton
                    url='/percorsi'
                />
            </div>
            <div className='opere2-items w-100 block'>
                { props.items.opere2.map((opere2List, i) => (
                    <Opere2Item
                        key={opere2List.id}
                        url={`/percorsi/opere/${opere2List.id}`}
                        title={opere2List.title}
                        body={opere2List.body}
                        previewId={opere2List.previewId}
                    />
                ))}
            </div>
        </div>
    )
}

export default Opere2ListContainer