import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import MapButtonIcon from "./MapButtonIcon";

const MapButton = (props) => {

    const { t } = useTranslation();

    return(
        <Link to={`${props.url}/maps`} className='absolute z-10 -right-4 top-3.5 inline-block float-right rounded'>
            <button
                className="lg:fixed lg:bottom-32 lg:right-12 lg:w-28 lg:h-28 lg:rounded-full lg:content-center lg:text-center lg:items-center lg:block inline-flex bg-headerGreen w-full justify-center rounded-md bg-opacity-100 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
                <span className='h-2 mr-2 lg:mr-0'><MapButtonIcon/></span>
                <span className='w-full' >{t('maps_button')}</span>
            </button>
        </Link>
    );
}

export default MapButton
