import React from 'react';
import DoveMangiarePreviewItem from "./DoveMangiarePreviewItem";

const DoveMangiarePreview = (props) =>{
    console.log(props)
    if(props.items.length === 0){
        return
    }
    console.log(props)
    return(
        <div>
            <DoveMangiarePreviewItem
                title={props.items[0].title}
                previewId={props.items[0].previewId}
                body={props.items[0].body}
                id={props.items[0].id}
            />
        </div>
    )
}

export default DoveMangiarePreview

