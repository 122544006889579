import React from "react";
import {
    Route,
    Routes,
    Navigate
} from "react-router-dom";
import Home from "./pages/Home";
import Scopri from "./pages/Scopri";
import Percorsi from "./pages/Percorsi";
import IlCretto from "./pages/IlCretto";
import LaStoria from "./pages/LaStoria";
import Novita from "./pages/Novita";
import MuseiDetail from "./components/scopri/musei/MuseiDetail";

import './App.css';
import MainMenu from "./components/MainMenu";
import EventDetail from "./components/novita/eventi/EventDetail";
import NewsDetail from "./components/novita/notizie/NewsDetail";
import DoveMangiareDetail from "./components/scopri/dove-mangiare/DoveMangiareDetail";
import DoveDormireDetail from "./components/scopri/dove-dormire/DoveDormireDetail";
import ServiziDetail from "./components/scopri/servizi/ServiziDetail";
import OpereDetail from "./components/homepage/inEvidenza/OpereDetail";
import {useState} from "react";
import Cookies from "js-cookie";
import HomeScreen from "./pages/HomeScreen";

function App() {
    console.log(localStorage.getItem('homeScreen'))

    const [homeScreen, setHomeScreen] = useState(false)
    // const [homeScreen, setHomeScreen] = useState((Cookies.get('homeScreen') === null || Cookies.get('homeScreen') === undefined) ? false : Cookies.get('homeScreen'))


    const homeScreenClickHandler = () => {
        setHomeScreen(true)


        Cookies.set('homeScreen', true)
        // localStorage.set('homeScreen', true)
    }

    // If in 5 minutes screen not touch load ScreenSaver component


  return (

    <div className="App pb-32">


        {!homeScreen && <HomeScreen onClick={
            homeScreenClickHandler
        }/>}
        <MainMenu >
        </MainMenu>
        <main>

            <Routes>

                <Route
                    path="/homepage"
                    index
                    exact={true}
                    element={<Home />}
                >
                </Route>
                <Route
                    path="/scopri/musei"
                    exact={true}
                    element={<Navigate replace to="/scopri/musei/list" />}
                >
                </Route>
                <Route
                    path="/scopri"
                    exact={true}
                    element={<Navigate replace to="/scopri/musei/list" />}>
                </Route>
                <Route
                    path="/scopri/musei/list/:museiId/*"
                    exact={true}
                    element={<MuseiDetail />}>
                </Route>
                <Route
                    path="/scopri/*"
                    element={<Scopri />}>
                </Route>



                <Route
                    path="/scopri/dove-mangiare"
                    exact={true}
                    element={<Navigate replace to="/scopri/dove-mangiare/list" />}
                >
                </Route>
                <Route
                    path="/scopri/dove-mangiare/list/:doveMangiareId/*"
                    exact={true}
                    element={<DoveMangiareDetail />}>
                </Route>


                <Route
                    path="/scopri/dove-dormire"
                    exact={true}
                    element={<Navigate replace to="/scopri/dove-dormire/list" />}
                >
                </Route>
                <Route
                    path="/scopri/dove-dormire/list/:doveDormireId/*"
                    exact={true}
                    element={<DoveDormireDetail />}>
                </Route>

                <Route
                    path="/scopri/servizi"
                    exact={true}
                    element={<Navigate replace to="/scopri/servizi/list" />}
                >
                </Route>
                <Route
                    path="/scopri/servizi/list/:serviziId/*"
                    exact={true}
                    element={<ServiziDetail />}>
                </Route>


                <Route
                    path="/percorsi"
                    exact={true}
                    element={<Navigate replace to="/percorsi/maps" />}
                >
                </Route>
                <Route
                    path="/percorsi/*"
                    element={<Percorsi />}>
                </Route>
                {/*<Route*/}
                {/*    path="/percorsi/:percorsiId/:tappeId/:opereId/*"*/}
                {/*    // element={<MuseiDetail />}*/}
                {/*>*/}
                {/*</Route>*/}

                <Route
                    path="/percorsi/opere/:opereId/*"
                    element={<OpereDetail />}>
                </Route>


                <Route
                    path="/il-cretto/*"
                    element={<IlCretto />}>
                </Route>
                <Route
                    path="/la-storia/*"
                    element={<LaStoria />}>
                </Route>
                <Route
                    path="/novita/*"
                    exact={true}
                    element={<Novita />}>
                </Route>
                <Route
                    path="/novita/eventi/:eventId/*"
                    element={<EventDetail />}>
                </Route>
                <Route
                    path="/novita/notizie/:newsId/*"
                    element={<NewsDetail />}>
                </Route>
            </Routes>
        </main>
    </div>
  )
}

export default App;
