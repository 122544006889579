// import Opere2Item from "./Opere2Item";
import React from "react";
import {Route, Routes} from "react-router-dom";
// import {Refreshable} from 'react-router-refreshable';
// import {useTranslation} from "react-i18next";
// import MapButton from "../../UI/MapButton";
import Maps from "../../scopri/musei/Maps";
import DoveMangiareListContainer from "../../scopri/dove-mangiare/DoveMangiareListContainer";
import Opere2ListContainer from "./OpereListContainer";
import PercorsiList from "./PercorsiList";


const Opere2List = (props) =>{

    // const { t } = useTranslation();
    console.log(props)

    return(
        <section className="overflow-hidden text-gray-700 events-container">
            <Routes>
                <Route path="list" exact={true} element={
                    <Opere2ListContainer
                        items={props}
                    />
                }>
                </Route>
                {/*<Route path="maps" exact={true} element={*/}
                {/*    <PercorsiList/>*/}
                {/*}/>*/}
            </Routes>
        </section>
    )
}

export default Opere2List;
