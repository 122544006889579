import React, {useState, useEffect} from "react";
import cookies from "js-cookie";
import InEvidenzaList from "./InEvidenzaList";

import API from '../../api'
import Spinner from "../../UI/Spinner";


const InEvidenza = () => {
    const [eventsHighlighted, setEventsHighlighted] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // const currentLanguageInterval = setInterval(() => {
            const currentLanguageCode = localStorage.getItem('i18nextLng');

            // if(currentLanguageCode){
            //     clearInterval(currentLanguageInterval)



        async function getEventsHighlighted() {
                setIsLoading(true);
                const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/homepage/805de81e-94f8-4d4f-843e-f5eccdc8d14b/?include=field_in_evidenza`);
                const eventsHighlighted = await response.data;

                const transformedEventsHighlighted = eventsHighlighted.included.map(eventsHighlightedData => {
                    return{
                        id: eventsHighlightedData.id,
                        title: eventsHighlightedData.attributes.title,
                        body: eventsHighlightedData.attributes.body,
                        previewId: eventsHighlightedData.relationships.field_immagine_anteprima.data.id
                    };
                });

                setEventsHighlighted(transformedEventsHighlighted);
                setIsLoading(false);
            return false

        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });

        myPromise
            .then(getEventsHighlighted)
        // }
        // },100);

    }, []);

    return(
        <section>
            {!isLoading && eventsHighlighted.length > 0 &&
                <InEvidenzaList
                    eventsHighlighted={eventsHighlighted}
                />
            }
            {!isLoading && eventsHighlighted.length === 0 && <p>No in evidenza data</p>}
            {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
        </section>
    )

}

export default InEvidenza;
