import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import cookies from "js-cookie";

import API from '../../api'


const EventsItem = (props) => {
    console.log(props)
    const [isLastElement, setIsLastElement] = useState(false);
    const [eventsImage, setEventsImage] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const currentLanguageCode = localStorage.getItem('i18nextLng')
        if(props.order === props.last){
            setIsLastElement(true)
        }
        async function fetchEventsImageHandler() {
                setIsLoading(true);
                const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/media/image/${props.previewId}?include=field_media_image`)
                const dataEventsImage = await response.data;

                if(dataEventsImage){
                    const transformedEventsImage = dataEventsImage.included.map(eventsImageData => {
                        return{
                            previewImage: eventsImageData.attributes.uri.url
                        };
                    });
                    setEventsImage(transformedEventsImage);
                    setIsLoading(false);
                }


            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });

        myPromise
            .then(fetchEventsImageHandler)


    }, [props.previewId]);
    return (
        <div className={`snap-end scroll-mx-6 shrink-0 w-72 ${isLastElement ? 'mr-8' : '' }`}
        >
            <div className={`flex`} key={props.id}>
                <Link to={props.url} className='w-full'>
                    <div className="grid w-full">
                        <div className={`relative o verflow-hidden shadow-lg cursor-pointer shadow-up ${props.imgRounded}`}>
                            {!isLoading && eventsImage.length > 0 &&
                                    <img className="object-cover w-full h-48 rounded-lg"
                                         src={`https://gibellina.bbsitalia.com/${eventsImage[0].previewImage}`}
                                         alt="Flower and sky"/>
                            }

                            <div className="absolute bottom-0 left-0 px-6 py-4">
                                    <h4 className="mb-3 text-l font-normal tracking-tight text-white">
                                        {(props.title === null || props.title === undefined) ? '' : props.title}
                                    </h4>
                                {/*<p className="leading-normal text-gray-100">Lorem ipsum dolor, sit amet cons ectetur adipis icing elit.*/}
                                {/*    Praesen tium, quibusdam facere quo laborum maiores sequi nam tenetur laud.</p>*/}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default EventsItem;
