import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import cookies from "js-cookie";

import API from '../../api'
import Spinner from "../../UI/Spinner";
// import BackButtonIcon from "../../UI/BackButtonIcon";
const currentLanguageCode = localStorage.getItem('i18nextLng')

const DoveMangiareItem = (props) =>{
    const [doveMangiareImage, setDoveMangiareImage] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchDoveMangiareImageHandler() {
            setIsLoading(true);
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/media/image/${props.previewId}?include=field_media_image`)
            const dataDoveMangiareImage = await response.data;


            if(dataDoveMangiareImage){
                const transformedDoveMangiareImage = dataDoveMangiareImage.included.map(doveMangiareImageData => {
                    return{
                        previewImage: doveMangiareImageData.attributes.uri.url
                    };
                });
                setDoveMangiareImage(transformedDoveMangiareImage);
                setIsLoading(false);
            }


            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });

        myPromise
            .then(fetchDoveMangiareImageHandler)


    }, [props.previewId]);
    return(
        <div className="flex pb-8" key={props.id}>
            <Link to={props.url} className='w-full'>
                <div className="bg-white flex w-full">
                    {!isLoading && doveMangiareImage.length > 0 &&
                        <div
                            className='relative flex-none sm:self-start lg:self-auto xl:self-start w-32 h-32 overflow-hidden rounded-lg shadow-lg cursor-pointer'>
                            {/*{(props.imgAnteprima === null || props.imgAnteprima === undefined) ? '' : props.imgAnteprima > 0  &&*/}
                            <img className="object-cover w-full h-48"
                                 src={`https://gibellina.bbsitalia.com/${doveMangiareImage[0].previewImage}`}
                                 alt="Flower and sky"/>
                            {/*}*/}
                        </div>
                    }
                    {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
                    {!isLoading && doveMangiareImage.length === 0 && <p>No doveMangiareImage data</p>}
                    <div className="self-start flex-auto flex flex-wrap items-baseline pl-6">
                        <h6 className="mb-1 font-normal tracking-tight text-black w-full">{props.title}</h6>
                        {/*<p className="leading-normal text-black">Lorem ipsum dolor, sit amet cons ectetur adipis icing elit.*/}
                        {/*    Praesen tium, quibusdam facere quo laborum maiores sequi nam tenetur laud.</p>*/}
                        <div className='w-100 block'>

                        {(props.tag === null || props.tag === undefined) ? '' : <div className='w-100 mr-3' dangerouslySetInnerHTML={{ __html: props.tag.processed}} />}
                        </div>


                        <div className='w-100 block'>
                        {(props.body === null || props.body === undefined) ? '' :                            <div dangerouslySetInnerHTML={{ __html: props.body.summary.substr(0, Math.min(props.body.summary.substr(0, 60).length, props.body.summary.substr(0, 60).lastIndexOf(" "))) + '...'}} />
                        }
                        </div>
                        <div className='w-full'>

                        <span className='h-9 mt-2  inline-flex bg-headerGreen justify-center rounded-md bg-opacity-100 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 z-10'>
                            <span className='w-full' >
                                Scopri di più
                            </span>
                                 <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" width="24.75" height="15.455" viewBox="0 0 24.75 15.455">
                                  <g id="Arrow_right" data-name="Arrow right" transform="translate(0.75 1.061)">
                                    <path id="Tracciato_10470" data-name="Tracciato 10470" d="M7.5,19.133H29.55" transform="translate(-7.5 -12.467)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    <path id="Tracciato_10471" data-name="Tracciato 10471" d="M18,7.5l6.667,6.667L18,20.833" transform="translate(-1.417 -7.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  </g>
                                </svg>

                        </span>
                        </div>

                    </div>
                </div>
            </Link>
        </div>
    )
}

export default DoveMangiareItem;
