import {useState, useEffect} from "react";

const GalleryItems = (props) => {
    // console.log(props.imgProp.width)

    const [isLastElement, setIsLastElement] = useState(false);
    useEffect(() => {

        if(props.order === props.last){
            setIsLastElement(true)
        }
    }, []);
    // console.log(props)

    return (
        <a
            href={`https://gibellina.bbsitalia.com/${props.data.url}`}
            key={'Gallery-' + props.i}
            target="_blank"
            rel="noreferrer"
            data-pswp-width={props.imgProp[props.order].width}
            data-pswp-height={props.imgProp[props.order].height}
            data-cropped="true"
            // className='shrink-0 w-80 h-40 rounded-lg shadow-xl bg-white'
        >


            <div className={`relative overflow-hidden shadow-lg cursor-pointer rounded-2xl ${isLastElement ? 'mr-8' : '' }`} key={props.i}>
                <img className={`object-cover w-full h-48 shrink-0 w-80 h-40 rounded-lg shadow-xl bg-white `}
                     src={`https://gibellina.bbsitalia.com/${props.data.url}`}
                     alt={props.imgProp[props.order].alt}
                />
                {/*<div className="absolute bottom-0 left-0 px-6 py-4">*/}
                {/*    <small className='text-white uppercase'>Evento</small>*/}
                {/*    <h1 className="mb-3 text-3xl font-normal tracking-tight text-white font-medium">*/}
                {/*        {(event[0].title === null || event[0].title === undefined) ? '' : event[0].title}*/}
                {/*    </h1>*/}
                {/*    /!*<p className="leading-normal text-gray-100">Lorem ipsum dolor, sit amet cons ectetur adipis icing elit.*!/*/}
                {/*    /!*    Praesen tium, quibusdam facere quo laborum maiores sequi nam tenetur laud.</p>*!/*/}
                {/*</div>*/}
            </div>
        </a>
    );
}

export default GalleryItems
