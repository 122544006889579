import LanguageDropdown from "../components/homepage/LanguageDropdown";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import InEvidenza from "../components/homepage/inEvidenza/InEvidenza";
import Opere from "../components/homepage/inEvidenza/Opere";
import HomeScreen from "./HomeScreen";
import Cookies from "js-cookie";
import Modal from "../components/UI/modal/Modal";

const Home = () => {
    const { t } = useTranslation();
    const [modalOpen, isModalOpen] = useState(true)
    const [isOpen, setIsOpen] = useState(true);

    return (

        <section>
            {/*<button  onClick={() => setIsOpen(true)}>*/}
            {/*    Open Modal*/}
            {/*</button>*/}
            {isOpen && <Modal setIsOpen={setIsOpen} />}
            <div className='header-content bg-headerGreen px-8 py-8 text-white text-4xl'>
                {/*<div className='absolute top-5 right-5 w-56 h-12 text-right'>*/}
                    <div className='fixed bottom-72 right-5 w-56 h-12 text-right z-20'>
                    <div className='relative inline-block text-left'>
                        <LanguageDropdown/>
                    </div>
                </div>
                <h1 className='font-medium mt-12'>{t("welcome")}</h1>
            </div>
            <h2 className='ml-8 mt-5 mb-5 font-normal text-greenText'>{t("in_evidenza_title")}</h2>
            <div className='eventsHighlighted'>
                <InEvidenza />
            </div>

            <h2 className='ml-8 mt-3 mb-5 font-normal text-greenText'>{t("opere_title")}</h2>
            <div className='opereHighlighted'>
                <Opere />
            </div>
        </section>
    );
}

export default Home;
