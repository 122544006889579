import API from "../../api";
import ServiziDetailTagList from "./ServiziDetailTagList";

const ServiziDetailTag = (props) => {

    console.log(props.tag)
    // async function getTaxonomy() {
    //     const currentLanguageCode = localStorage.getItem('i18nextLng')
    //     console.log(servizi[0].taxonomy_primaria[0].meta.drupal_internal__target_id)
    //     let newResponse = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonget/taxonomy-term/${servizi[0].taxonomy_primaria[0].meta.drupal_internal__target_id}`)
    //     const dataServizio2 = await newResponse.data
    //     console.log(dataServizio2[0].name)
    // }
    //
    // getTaxonomy()

    return(
        <div>

            {props.tag &&
             props.tag.map((tag, i) => (
                <ServiziDetailTagList
                    key={i}
                    tag={tag}
                />
            ))
            }

        </div>

    );}
export default ServiziDetailTag;
