import './HomeScreen.css'

import homeImage from './GibellinaMod02.png'
import homeImageGif from './animation_500_l50u4nyq.gif'
import logo from './logo.png'
import {Navigate, NavLink, Route} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import LanguageDropdown from "../components/homepage/LanguageDropdown";
import LanguageBox from "../components/UI/LanguageBox";


const HomeScreen = (props) => {
    const { t } = useTranslation()


    return(
        <div>
            <NavLink
                to={`/homepage`}
            >

                <div onClick={props.onClick} className='pt-8 pb-8 absolute top-0 left-0 w-full h-screen bg-white z-9999'>
                    <img src={logo} className='w-64 pl-10' alt='logo'/>
                    <h1 className='text-greenText pl-16 pr-16 font-medium text-left leading-10'>{t("benvenuto_gibellina")}</h1>
                    <h1 className='text-greenText pl-16 pr-16 font-medium text-left leading-10'>{t("benvenuto_gibellina2")}</h1>

                    <p className='text-greenText pl-16 pr-16 mt-4 text-left leading-5'>{t("descrizione_benvenuto")}</p>


                    <img src={homeImage} className='absolute bottom-0 left-0 w-full'/>

                    <div className='centratoHome absolute bottom-32 content-center text-center'>



                        <div className='content-center text-center'>

                            <svg xmlns="http://www.w3.org/2000/svg" width="151" height="151" viewBox="0 0 151 151">
                                <g id="Raggruppa_800" data-name="Raggruppa 800" transform="translate(-120 -551)">
                                    <circle id="Ellisse_322" data-name="Ellisse 322" cx="75.5" cy="75.5" r="75.5" transform="translate(120 551)" fill="#fff" opacity="0.3"/>
                                    <circle id="Ellisse_321" data-name="Ellisse 321" cx="55.5" cy="55.5" r="55.5" transform="translate(140 571)" fill="#fff" opacity="0.8"/>
                                </g>
                            </svg>
                            <img src={homeImageGif} className='absolute bottom-10 right-2 w-36 h-36 ml-3'/>
                            <p className='text-white font-bold text-xl'>{t("inizia_tap")}</p>
                        </div>

                    </div>

                </div>

            </NavLink>
            <LanguageBox/>

        </div>
    )
}

export default HomeScreen
