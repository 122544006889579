const BackButtonIcon = () =>{
    return(<svg className='h-4 mr-2 mt-0.5 lg:w-10 lg:h-10 lg:mt-3 lg:text-center lg:m-auto' xmlns="http://www.w3.org/2000/svg" width="24.75" height="15.455" viewBox="0 0 24.75 15.455">
            <g id="Arrow_right" data-name="Arrow right" transform="translate(24 14.394) rotate(180)">
                <path id="Tracciato_10470" data-name="Tracciato 10470" d="M7.5,19.133H29.55" transform="translate(-7.5 -12.467)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Tracciato_10471" data-name="Tracciato 10471" d="M18,7.5l6.667,6.667L18,20.833" transform="translate(-1.417 -7.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
        </svg>
    )
}

export default BackButtonIcon