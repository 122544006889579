import Events from "../components/novita/eventi/Events";
import News from "../components/novita/notizie/News";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import cookies from "js-cookie";
import Spinner from "../components/UI/Spinner";

const Novita = () => {
    const [events, setEvents] = useState([]);
    const [notizie, setNotizie] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [error, setError] = useState(null)
    const [error2, setError2] = useState(null)

    const { t } = useTranslation();

    useEffect(() => {
        const currentLanguageCode = localStorage.getItem('i18nextLng')
        async function fetchEventsHandler() {
            try {
                setIsLoading(true);
                const response = await fetch(`https://gibellina.bbsitalia.com/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/eventi?include=field_immagine_anteprima.field_media_image`)
                const dataEventi = await response.json();
                if(!response.ok){
                    throw new Error('Something went wrong!');
                }

                const transformedEvents = dataEventi.data.map(eventsData => {
                    return{
                        id: eventsData.id,
                        title: eventsData.attributes.title,
                        body: eventsData.attributes.body,
                        previewId: eventsData.relationships.field_immagine_anteprima.data.id
                    };
                });

                setEvents(transformedEvents);
                setIsLoading(false);
            } catch (error){
                setError(error.message);
            }
            setIsLoading(false);

            return false
        }
        async function fetchNotizieHandler() {
            try {
                setIsLoading2(true);
                const response = await fetch(`https://gibellina.bbsitalia.com/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/news?include=field_immagine_anteprima.field_media_image`)
                const dataNotizie = await response.json();

                    if (!response.ok) {
                        throw new Error('Something went wrong!');
                    }
                if(dataNotizie) {

                    const transformedNotizie = dataNotizie.data.map(notizieData => {
                        return {
                            id: notizieData.id,
                            title: notizieData.attributes.title,
                            body: notizieData.attributes.body,
                            previewId: notizieData.relationships.field_immagine_anteprima.data.id
                        };
                    });
                    setNotizie(transformedNotizie);
                    setIsLoading2(false);
                }


            } catch (error2){
                setError2(error2.message);
            }
            setIsLoading2(false);

            return false
        }
        const myPromise = new Promise((resolve, reject) => {
                resolve('foo');
        });

        myPromise
            .then(fetchEventsHandler)
            .then(fetchNotizieHandler)
    }, []);

    return (
        <div className='novita-page'>
            <div className='header-content bg-headerGreen px-8 py-8 text-white text-4xl'>
                <h1 className='font-medium'>{t("novita_title")}</h1>
            </div>
            {/*<button className='border-2 border-black p-3' onClick={fetchEventsHandler}>Fetch data</button>*/}
            <h2 className='ml-8 mt-5 mb-5 font-normal text-greenText'>{t("section_events_title")}</h2>
            {!isLoading && events.length > 0 &&
                <div className='eventi'>
                    <Events events={events} />
                </div>
            }
            {!isLoading && events.length === 0 && <p>No event data</p>}
            {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            {!isLoading && error && <p>{error.message}</p>}

            <h2 className='ml-8 mt-5 mb-5 font-normal text-greenText'>{t("section_news_title")}</h2>
            {!isLoading2 && notizie.length > 0 &&
                <div className='notizie'>
                    <News notizie={notizie} />
                </div>
            }
            {isLoading2 && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            {!isLoading2 && error2 && <p>{error2.message}</p>}

            {!isLoading && notizie.length === 0 && <p>No notizie data</p>}


        </div>
    );
}

export default Novita;
