import L from "leaflet";

export default L.icon({
    iconSize: [60, 60],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "/assets/currentPosition.svg",
    // shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

