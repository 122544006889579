import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import cookies from "js-cookie";
// import {useTranslation} from "react-i18next";
import icon from '../icons/marker-icon.png';

import API from '../components/api'
import BackButton from "../components/UI/BackButton";
import CardImgHeader from "../components/novita/UI/CardImgHeader";
import Reader from "../components/novita/eventi/Reader";
import Informazioni from "../components/novita/UI/Informazioni";
import Gallery from "../components/UI/gallery/Gallery";
import Spinner from "../components/UI/Spinner";

let DefaultIcon = L.icon({
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

const IlCretto = () => {

    // const { t } = useTranslation();

    const params = useParams()
    const [ilCretto, setIlCretto] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchIlCrettoHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')

            setIsLoading(true);
            let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/pagina_informativa/294902ba-57c5-43c8-88d1-c1d0bdef9cae?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image`)

            const dataIlCretto = await response.data;
            const dataIlCrettoArray = [dataIlCretto]


            const transformedIlCretto = dataIlCrettoArray.map(ilCrettoData => {
                return{
                    id: ilCrettoData.data.id,
                    title: ilCrettoData.data.attributes.title,
                    body: ilCrettoData.data.attributes.body,
                    date_start: ilCrettoData.data.attributes.field_data_inizio,
                    date_end: ilCrettoData.data.attributes.field_data_fine,
                    latitude: ilCrettoData.data.attributes.field_latitudine,
                    longitude: ilCrettoData.data.attributes.field_longitudine,
                    preview: dataIlCretto.included[1].attributes.uri.url,
                    galleria: dataIlCretto.included,
                    audio: dataIlCretto.included,
                    dove: ilCrettoData.data.attributes.field_dove,
                    prezzi: ilCrettoData.data.attributes.field_prezzi,
                    contatti: ilCrettoData.data.attributes.contatti,
                };
            });

            // setIlCretto((prevState => {
            //     return [prevState, ...transformedIlCretto]
            // }));
            setIlCretto(transformedIlCretto);
            setIsLoading(false);


        }
        fetchIlCrettoHandler()


    }, [params.ilCrettoId]);
    return(

        <div>
            <section>
                <BackButton
                    link={'/novita'}
                />
                {!isLoading && ilCretto.length > 0 &&
                    <div key={ilCretto.id}>
                        <CardImgHeader
                            className='relative'
                            preview={ilCretto[0].preview}
                            title={ilCretto[0].title}
                            // category={t('ilcretto_category')}
                            isLoading={isLoading}
                            data={ilCretto[0]}
                        />

                        <div className='content p-8 lg:px-24 break-words'>
                            <Reader
                                value={[ilCretto[0].title.replace(/<[^>]*>?/gm, ''), (ilCretto[0].body === null || ilCretto[0].body === undefined) ? '' : ilCretto[0].body.processed.replace(/<[^>]*>?/gm, '')]}
                                body={(ilCretto[0].body === null || ilCretto[0].body === undefined) ? '' : ilCretto[0].body.processed.replace(/<[^>]*>?/gm, '')}
                                audio={ilCretto[0].audio}
                            />
                            <Informazioni
                                dove={ilCretto[0].dove}
                                dateFrom={(ilCretto[0].date_start === null || ilCretto[0].date_start === undefined) ? '' : ilCretto[0].date_start}
                                dateTo={(ilCretto[0].date_end === null || ilCretto[0].date_end === undefined) ? '' : ilCretto[0].date_end}
                                prezzi={ilCretto[0].prezzi}
                            />
                            {ilCretto[0].body &&
                                <div className='description mt-3'>
                                    <span className='font-medium mb-3 block'>Descrizione</span>
                                    {(ilCretto[0].body === null || ilCretto[0].body === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: ilCretto[0].body.processed}} />}


                                </div>
                            }

                        </div>
                        <div className='galleria'>
                            {/*<section className="overflow-hidden text-gray-700 ilCrettos-container -ml-28 mt-8 mb-9">*/}
                            <section className="overflow-hidden text-gray-700 ilCrettos-container mt-8 mb-9">
                                {/*<div className="container px-1 py-2 lg:pt-12 lg:px-32 ">*/}
                                <div className="px-1 py-2 w-full ">
                                    <div className="flex -m-1 md:-m-2">
                                        <Gallery
                                            gallery={ilCretto[0].galleria}
                                        />
                                        {/*{props.children}*/}
                                        {/*</Galleria>*/}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {(ilCretto[0].latitude === null || ilCretto[0].latitude === undefined) ? '' : ilCretto[0].latitude > 0 && (ilCretto[0].longitude === null || ilCretto[0].longitude === undefined) ? '' : ilCretto[0].longitude  > 0 &&

                            <MapContainer
                                center={[(ilCretto[0].latitude === null || ilCretto[0].latitude === undefined) ? '' : ilCretto[0].latitude, (ilCretto[0].longitude === null || ilCretto[0].longitude === undefined) ? '' : ilCretto[0].longitude]}
                                zoom={13}
                                scrollWheelZoom={false}
                                className='w-full h-72 z-0'>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[(ilCretto[0].latitude === null || ilCretto[0].latitude === undefined) ? '' : ilCretto[0].latitude, (ilCretto[0].longitude === null || ilCretto[0].longitude === undefined) ? '' : ilCretto[0].longitude]}>
                                    <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        }
                    </div>
                }
                {!isLoading && ilCretto.length === 0 && <p>No ilCretto data</p>}
                {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            </section>
        </div>
    );}


export default IlCretto;
