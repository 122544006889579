import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import Gallery from "../../UI/gallery/Gallery";
import cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import icon from '../../../icons/marker-icon.png';
import Informazioni from "../../novita/UI/Informazioni";
import CardImgHeader from "../../novita/UI/CardImgHeader";

import './DoveMangiareDetail.css'

import API from '../../api'
import BackButton from "../../UI/BackButton";
import Spinner from "../../UI/Spinner";
import Reader from "../../novita/eventi/Reader";
import GoToDirection from "../../UI/GoToDirection";
import ServiziDetailTag from "../servizi/ServiziDetailTag";
import ServiziDetailTagSecondaria from "../servizi/ServiziDetailTagSecondaria";
let DefaultIcon = L.icon({
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

const DoveMangiareDetail = (props) => {

    const { t } = useTranslation();

    const params = useParams()
    const [doveMangiare, setDoveMangiare] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        async function fetchDoveMangiareHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')

            setIsLoading(true);
            let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/dove_mangiare/${params.doveMangiareId}?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image`)

            const dataDoveMangiareo = await response.data;
            setError(null)
            const dataDoveMangiareoArray = [dataDoveMangiareo]

            console.log(dataDoveMangiareoArray)

            const transformedDoveMangiare = dataDoveMangiareoArray.map(doveMangiareData => {
                return{
                    id: doveMangiareData.data.id,
                    title: doveMangiareData.data.attributes.title,
                    body: doveMangiareData.data.attributes.body,
                    date_start: doveMangiareData.data.attributes.field_data_inizio,
                    date_end: doveMangiareData.data.attributes.field_data_fine,
                    latitude: doveMangiareData.data.attributes.field_latitudine,
                    longitude: doveMangiareData.data.attributes.field_longitudine,
                    preview: dataDoveMangiareo.included[1].attributes.uri.url,
                    galleria: dataDoveMangiareo.included,
                    audio: dataDoveMangiareo.included,
                    dove: doveMangiareData.data.attributes.field_dove,
                    prezzi: doveMangiareData.data.attributes.field_prezzi,
                    contatti: doveMangiareData.data.attributes.field_contatti,
                    tag: doveMangiareData.data.attributes.field_tag_testo,
                    orari: doveMangiareData.data.attributes.field_orari,
                    ragione_sociale: doveMangiareData.data.attributes.field_ragione_s,
                    taxonomy_primaria: doveMangiareData.data.relationships.field_dove_mangiare_primaria.data,
                    taxonomy_secondaria: doveMangiareData.data.relationships.field_dove_mangiare_secondaria.data,

                };
            });
            setDoveMangiare(transformedDoveMangiare);
            setIsLoading(false);
        }
        fetchDoveMangiareHandler()
    }, [params.doveMangiareId]);

    return(
        <div>
            <section>
                <BackButton
                    link={'/scopri/dove-mangiare/list'}
                />

                {!isLoading && doveMangiare.length > 0 &&
                    <div key={doveMangiare.id}>
                        <CardImgHeader
                            className='relative'
                            preview={doveMangiare[0].preview}
                            title={doveMangiare[0].title}
                            primaria={doveMangiare[0].taxonomy_primaria}
                            tag={(doveMangiare[0].tag === null || doveMangiare[0].tag === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: doveMangiare[0].tag.processed}} />
                            }
                            isLoading={isLoading}
                            data={doveMangiare[0]}
                        />

                        <div className='content p-8 lg:px-24 break-words'>
                            <GoToDirection
                                latitude={doveMangiare[0].latitude}
                                longitude={doveMangiare[0].longitude}
                            />
                            <Reader
                                value={[doveMangiare[0].title.replace(/<[^>]*>?/gm, ''), (doveMangiare[0].body === null || doveMangiare[0].body === undefined) ? '' : doveMangiare[0].body.processed.replace(/<[^>]*>?/gm, '')]}
                                body={(doveMangiare[0].body === null || doveMangiare[0].body === undefined) ? '' : doveMangiare[0].body.processed.replace(/<[^>]*>?/gm, '')}
                                audio={doveMangiare[0].audio}
                            />
                            <Informazioni
                                dove={doveMangiare[0].dove}
                                dateFrom={(doveMangiare[0].date_start === null || doveMangiare[0].date_start === undefined) ? '' : doveMangiare[0].date_start}
                                dateTo={(doveMangiare[0].date_end === null || doveMangiare[0].date_end === undefined) ? '' : doveMangiare[0].date_end}
                                prezzi={doveMangiare[0].prezzi}
                            />
                            {/*{(doveMangiare[0].body === null || doveMangiare[0].body === undefined) ? '' : doveMangiare[0].body > 0 &&*/}
                            <ServiziDetailTag tag={doveMangiare[0].taxonomy_primaria} />
                            <ServiziDetailTagSecondaria tag={doveMangiare[0].taxonomy_secondaria} />

                            {doveMangiare[0].orari &&
                                <div className='orari mt-3'>
                                    <span className='font-medium mb-3 block lg:text-xl'>{t('orari')}</span>
                                    {(doveMangiare[0].orari === null || doveMangiare[0].orari === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: doveMangiare[0].orari.processed}} />}
                                </div>
                            }
                            {doveMangiare[0].contatti &&

                                <div className='contatti mt-3'>
                                    <span className='font-medium mb-3 block lg:text-xl'>{t('contatti')}</span>
                                    {(doveMangiare[0].contatti === null || doveMangiare[0].contatti === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: doveMangiare[0].contatti.processed}} />}
                                </div>
                            }

                            {doveMangiare[0].body &&
                                <div className='description mt-3'>
                                    <span className='font-medium mb-3 block lg:text-xl'>{t('descrizione')}</span>
                                    {(doveMangiare[0].body === null || doveMangiare[0].body === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: doveMangiare[0].body.processed}} />}
                                </div>
                            }
                            {/*}*/}
                        </div>
                        <div className='galleria'>
                            <section className="overflow-hidden text-gray-700 dove-mangiare-container mt-8 mb-9">
                                <div className="px-1 py-2">
                                    <div className="flex -m-1 md:-m-2">
                                        <Gallery
                                            gallery={doveMangiare[0].galleria}
                                        >
                                            {props.children}
                                        </Gallery>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {(doveMangiare[0].latitude === null || doveMangiare[0].latitude === undefined) ? '' : doveMangiare[0].latitude > 0 && (doveMangiare[0].longitude === null || doveMangiare[0].longitude === undefined) ? '' : doveMangiare[0].longitude  > 0 &&

                            <MapContainer
                                center={[(doveMangiare[0].latitude === null || doveMangiare[0].latitude === undefined) ? '' : doveMangiare[0].latitude, (doveMangiare[0].longitude === null || doveMangiare[0].longitude === undefined) ? '' : doveMangiare[0].longitude]}
                                zoom={13}
                                scrollWheelZoom={false}
                                className='w-full h-72 lg:h-96 z-0'>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[(doveMangiare[0].latitude === null || doveMangiare[0].latitude === undefined) ? '' : doveMangiare[0].latitude, (doveMangiare[0].longitude === null || doveMangiare[0].longitude === undefined) ? '' : doveMangiare[0].longitude]}>
                                    {/*<Popup>*/}
                                    {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                    {/*</Popup>*/}
                                </Marker>
                            </MapContainer>
                        }
                        {/*<a target='_blank' rel="noreferrer" href={`http://maps.google.com/maps?saddr=50,10&daddr=50,20`}>Test</a>*/}

                    </div>
                }
                {!isLoading && doveMangiare.length === 0 && <p>No doveMangiare data</p>}
                {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
                {!isLoading && error && <p>{error.message}</p>}
            </section>
        </div>
    );}

export default DoveMangiareDetail;
