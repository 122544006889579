import Opere2List from "./Opere2List";
import React, {useEffect, useState} from "react";
import cookies from "js-cookie";

import API from '../../api'
import Spinner from "../../UI/Spinner";


const Opere2 = () =>{
    const [opere2, setOpere2] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchOpere2Handler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')
            setIsLoading(true);
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/opere?include=field_immagine_anteprima.field_media_image`)
            const dataOpere2 = await response.data;
            console.log(dataOpere2)
            if(dataOpere2){
                const transformedOpere2 = dataOpere2.data.map(opere2Data => {
                    return{
                        id: opere2Data.id,
                        title: opere2Data.attributes.title,
                        body: opere2Data.attributes.body,
                        latitude: opere2Data.attributes.field_latitudine,
                        longitude: opere2Data.attributes.field_longitudine,
                        previewId: opere2Data.relationships.field_immagine_anteprima.data.id
                    };
                });
                setOpere2(transformedOpere2);
                setIsLoading(false);
            }
            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(fetchOpere2Handler)
    }, []);
    return(
        <section className='w-full lg:w-10/12'>
            {!isLoading && opere2.length > 0 &&
                <div className='opere2'>
                    <Opere2List
                        opere2={opere2}
                    />
                </div>
            }
            {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            {!isLoading && opere2.length === 0 && <p>No opere2 data</p>}
        </section>
    )
}

export default Opere2;
