import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import CardImgHeader from "../../novita/UI/CardImgHeader";
import Gallery from "../../UI/gallery/Gallery";
import './OpereDetail.css'
import cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import Informazioni from "../../novita/UI/Informazioni";
import icon from '../../../icons/marker-icon.png';

import API from '../../api'
import BackButton from "../../UI/BackButton";
import Spinner from "../../UI/Spinner";
import Map from "./Map";
import GoToDirection from "../../UI/GoToDirection";
import Reader from "../../novita/eventi/Reader";

import Speech from 'react-speech';
import {AudioPlayer} from "../../novita/eventi/AudioPlayer";


let DefaultIcon = L.icon({
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

const OpereDetail = () => {

    const { t } = useTranslation();

    const params = useParams()
    const [opere, setOpere] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const [geoLocation, setGeoLocation] = useState(false)

    const mapHandleClick = (() => {
        setGeoLocation(true)
    })

    useEffect(() => {
    async function fetchOpereHandler() {
        const currentLanguageCode = localStorage.getItem('i18nextLng')

            setIsLoading(true);
            let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/opere/${params.opereId}?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image,field_audio`)

            const dataOpereo = await response.data;
            const dataOpereoArray = [dataOpereo]
        console.log(dataOpereoArray)
            const transformedOpere = dataOpereoArray.map((opereData, i) => {
                return{
                    id: opereData.data.id,
                    title: opereData.data.attributes.title,
                    body: opereData.data.attributes.body,
                    date_start: opereData.data.attributes.field_data_inizio,
                    date_end: opereData.data.attributes.field_data_fine,
                    latitude: opereData.data.attributes.field_latitudine,
                    longitude: opereData.data.attributes.field_longitudine,
                    preview: dataOpereo.included[1].attributes.uri.url,
                    audio: dataOpereo.included,
                    galleria: dataOpereo.included,
                    dove: opereData.data.attributes.field_dove,
                    prezzi: opereData.data.attributes.field_prezzi,
                    contatti: opereData.data.attributes.field_contatti,
                };
            });

            // setOpere((prevState => {
            //     return [prevState, ...transformedOpere]
            // }));
            setOpere(transformedOpere);
            setIsLoading(false);


    }
    fetchOpereHandler()


    }, [params.opereId]);
    console.log(opere)
    return(

        <div>

            <section>
                <BackButton
                    link={'/percorsi'}
                />
                {!isLoading && opere.length > 0 &&
                    <div key={opere.id}>
                        <CardImgHeader
                            className='relative'
                            preview={opere[0].preview}
                            title={opere[0].title}
                            category={t('opere_category')}
                            isLoading={isLoading}
                            data={opere[0]}
                        />

                        <div className='content p-8 lg:px-24 break-words'>

                            {/*<AudioPlayer*/}
                            {/*    value={[opere[0].title.replace(/<[^>]*>?/gm, ''), (opere[0].body === null || opere[0].body === undefined) ? '' : opere[0].body.processed.replace(/<[^>]*>?/gm, '')]}*/}
                            {/*    body={(opere[0].body === null || opere[0].body === undefined) ? '' : opere[0].body.processed.replace(/<[^>]*>?/gm, '')}*/}
                            {/*    audio={opere[0].audio}*/}
                            {/*/>*/}

                            <GoToDirection
                                latitude={opere[0].latitude}
                                longitude={opere[0].longitude}
                            />
                            <Reader
                                value={[opere[0].title.replace(/<[^>]*>?/gm, ''), (opere[0].body === null || opere[0].body === undefined) ? '' : opere[0].body.processed.replace(/<[^>]*>?/gm, '')]}
                                body={(opere[0].body === null || opere[0].body === undefined) ? '' : opere[0].body.processed.replace(/<[^>]*>?/gm, '')}
                                audio={opere[0].audio}
                            />

                            <Informazioni
                                dove={opere[0].dove}
                                dateFrom={(opere[0].date_start === null || opere[0].date_start === undefined) ? '' : opere[0].date_start}
                                dateTo={(opere[0].date_end === null || opere[0].date_end === undefined) ? '' : opere[0].date_end}
                                prezzi={opere[0].prezzi}
                            />
                            {opere[0].body &&
                                <div className='description mt-3'>
                                    <span className='font-medium mb-3 block text-xl'>{t('descrizione')}</span>
                                    {(opere[0].body === null || opere[0].body === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: opere[0].body.processed}} />}


                                </div>
                            }
                            {opere[0].contatti &&

                            <div className='description mt-3'>
                                <span className='font-medium mb-3 block text-xl'>{t('contatti')}</span>
                                {(opere[0].contatti === null || opere[0].contatti === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: opere[0].contatti.processed}} />}


                            </div>
                            }

                        </div>
                        <div className='galleria'>
                            {/*<section className="overflow-hidden text-gray-700 opere-container -ml-28 mt-8 mb-9">*/}
                                <section className="overflow-hidden text-gray-700 opere-container mt-8 mb-9">
                                    {/*<div className="container px-1 py-2 lg:pt-12 lg:px-32 ">*/}
                                    <div className="px-1 py-2 w-full ">
                                    <div className="flex -m-1 md:-m-2">
                                        <Gallery
                                            gallery={opere[0].galleria}
                                        />
                                            {/*{props.children}*/}
                                        {/*</Galleria>*/}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/*<button  onClick={mapHandleClick} >Geolocation</button>*/}
                        {!geoLocation &&

                        (opere[0].latitude === null || opere[0].latitude === undefined) ? '' : opere[0].latitude > 0 && (opere[0].longitude === null || opere[0].longitude === undefined) ? '' : opere[0].longitude  > 0 &&

                            <MapContainer
                                center={[(opere[0].latitude === null || opere[0].latitude === undefined) ? '' : opere[0].latitude, (opere[0].longitude === null || opere[0].longitude === undefined) ? '' : opere[0].longitude]}
                                zoom={13}
                                scrollWheelZoom={false}
                                className='w-full h-72 lg:h-96 z-0'

                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker
                                    position={[(opere[0].latitude === null || opere[0].latitude === undefined) ? '' : opere[0].latitude, (opere[0].longitude === null || opere[0].longitude === undefined) ? '' : opere[0].longitude]}>
                                    {/*<Popup>*/}
                                    {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                    {/*</Popup>*/}
                                </Marker>
                            </MapContainer>
                        }
                        {/*{geoLocation &&*/}

                            {/*// <Map*/}
                            {/*//     marker={opere[0]}*/}
                            {/*//         currentPos={geoLocation}*/}
                            {/*// />*/}
                        {/*}*/}

                    </div>
                }
                {!isLoading && opere.length === 0 && <p>No opere data</p>}
                {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            </section>
        </div>
    );}


export default OpereDetail;
