import PercorsiList from "../components/percorsi/percorso/PercorsiList";
import React, {useEffect, useState} from "react";
import cookies from "js-cookie";

import API from '../components/api'
import {useTranslation} from "react-i18next";
import Opere2 from "../components/percorsi/percorso/Opere";
import { Route, Routes } from "react-router-dom";


const Percorsi = () => {

    const { t } = useTranslation()


    const [percorsi, setPercorsi] = useState([]);

    useEffect(() => {
        async function fetchPercorsiHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/percorso_museale?include=field_immagine_anteprima.field_media_image`)
            const dataPercorsi = await response.data;
            // console.log(dataPercorsi)
            if(dataPercorsi){
                const transformedPercorsi = dataPercorsi.data.map(percorsiData => {
                    return{
                        id: percorsiData.id,
                        title: percorsiData.attributes.title,
                        tempoMedio: percorsiData.attributes.field_tempo_medio_di_percorrenza,
                        tappa: percorsiData.relationships.field_tappe.data,
                        body: percorsiData.attributes.body,
                        previewId: percorsiData.relationships.field_immagine_anteprima.data.id
                        // latitude: percorsiData.attributes.field_latitudine,
                        // longitude: percorsiData.attributes.field_longitudine,
                    };
                });
                setPercorsi(transformedPercorsi);
            }
            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(fetchPercorsiHandler)
    }, []);
    return (
      <section>
          <div className='header-content bg-headerGreen px-8 py-8 text-white text-4xl'>
              <h1 className='font-medium'>{t("percorsi_title")}</h1>
          </div>
          <Routes>
              <Route path="/maps" exact={true} element={
              <PercorsiList
              items={percorsi}
          />
              }/>
          </Routes>
          <Opere2/>

      </section>
    );
}

export default Percorsi;
