import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import CardImgHeader from "../UI/CardImgHeader";
import Gallery from "../../UI/gallery/Gallery";
import './EventDetail.css'
import cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import Informazioni from "../UI/Informazioni";
import icon from '../../../icons/marker-icon.png';
import Reader from "./Reader";

import API from '../../api'
import BackButton from "../../UI/BackButton";
import Spinner from "../../UI/Spinner";
import GoToDirection from "../../UI/GoToDirection";

let DefaultIcon = L.icon({
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

const EventDetail = () => {

    const { t } = useTranslation();

    const params = useParams()
    const [event, setEvent] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
    async function fetchEventHandler() {
        const currentLanguageCode = localStorage.getItem('i18nextLng')

            setIsLoading(true);
            let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/eventi/${params.eventId}?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image`)

            const dataEvento = await response.data;
            const dataEventoArray = [dataEvento]
        console.log(dataEventoArray)


            const transformedEvent = dataEventoArray.map(eventData => {
                return{
                    id: eventData.data.id,
                    title: eventData.data.attributes.title,
                    body: eventData.data.attributes.body,
                    date_start: eventData.data.attributes.field_data_inizio,
                    date_end: eventData.data.attributes.field_data_fine,
                    latitude: eventData.data.attributes.field_latitudine,
                    longitude: eventData.data.attributes.field_longitudine,
                    preview: dataEvento.included[1].attributes.uri.url,
                    galleria: dataEvento.included,
                    audio: dataEvento.included,
                    dove: eventData.data.attributes.field_dove,
                    prezzi: eventData.data.attributes.field_prezzi,
                    contatti: eventData.data.attributes.field_contatti,
                };
            });

            // setEvent((prevState => {
            //     return [prevState, ...transformedEvent]
            // }));
            setEvent(transformedEvent);
            setIsLoading(false);


    }
    fetchEventHandler()


    }, [params.eventId]);
    console.log(event)
    return(

        <div>
            <section>
                <BackButton
                    link={'/novita'}
                />
                {!isLoading && event.length > 0 &&
                    <div key={event.id}>
                        <CardImgHeader
                            className='relative'
                            preview={event[0].preview}
                            title={event[0].title}
                            category={t('evento_category')}
                            isLoading={isLoading}
                            data={event[0]}
                        />

                        <div className='content p-8 lg:px-24 break-words'>

                            <GoToDirection
                                latitude={event[0].latitude}
                                longitude={event[0].longitude}
                            />

                            <Reader
                                value={[event[0].title.replace(/<[^>]*>?/gm, ''), (event[0].body === null || event[0].body === undefined) ? '' : event[0].body.processed.replace(/<[^>]*>?/gm, '')]}
                                body={(event[0].body === null || event[0].body === undefined) ? '' : event[0].body.processed.replace(/<[^>]*>?/gm, '')}
                                audio={event[0].audio}
                            />

                            <Informazioni
                                dove={event[0].dove}
                                dateFrom={(event[0].date_start === null || event[0].date_start === undefined) ? '' : event[0].date_start}
                                dateTo={(event[0].date_end === null || event[0].date_end === undefined) ? '' : event[0].date_end}
                                prezzi={event[0].prezzi}
                            />
                            {event[0].body &&
                                <div className='description mt-3'>
                                    <span className='font-medium mb-3 block text-xl'>{t('descrizione')}</span>
                                    {(event[0].body === null || event[0].body === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: event[0].body.processed}} />}


                                </div>
                            }
                            {event[0].contatti &&

                            <div className='description mt-3'>
                                <span className='font-medium mb-3 block text-xl'>{t('contatti')}</span>
                                {(event[0].contatti === null || event[0].contatti === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: event[0].contatti.processed}} />}


                            </div>
                            }

                        </div>
                        <div className='galleria'>
                            {/*<section className="overflow-hidden text-gray-700 events-container -ml-28 mt-8 mb-9">*/}
                                <section className="overflow-hidden text-gray-700 events-container mt-8 mb-9">
                                    {/*<div className="container px-1 py-2 lg:pt-12 lg:px-32 ">*/}
                                    <div className="px-1 py-2 w-full ">
                                    <div className="flex -m-1 md:-m-2">
                                        <Gallery
                                            gallery={event[0].galleria}
                                        />
                                            {/*{props.children}*/}
                                        {/*</Galleria>*/}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {(event[0].latitude === null || event[0].latitude === undefined) ? '' : event[0].latitude > 0 && (event[0].longitude === null || event[0].longitude === undefined) ? '' : event[0].longitude  > 0 &&

                            <MapContainer
                                center={[(event[0].latitude === null || event[0].latitude === undefined) ? '' : event[0].latitude, (event[0].longitude === null || event[0].longitude === undefined) ? '' : event[0].longitude]}
                                zoom={13}
                                scrollWheelZoom={false}
                                className='w-full h-72 lg:h-96 z-0'

                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[(event[0].latitude === null || event[0].latitude === undefined) ? '' : event[0].latitude, (event[0].longitude === null || event[0].longitude === undefined) ? '' : event[0].longitude]}>
                                    {/*<Popup>*/}
                                    {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                    {/*</Popup>*/}
                                </Marker>
                            </MapContainer>
                        }
                    </div>
                }
                {!isLoading && event.length === 0 && <p>No event data</p>}
                {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            </section>
        </div>
    );}


export default EventDetail;
