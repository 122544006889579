import React from 'react';
import OperePreviewItem from "./OperePreviewItem";

const OperePreview = (props) =>{
    console.log(props)
    if(props.items.length === 0){
        return
    }
    console.log(props)
    return(
        <div>
            <OperePreviewItem
                title={props.items.title}
                previewId={props.items.previewId}
                body={props.items.body}
                id={props.items.id}
            />
        </div>
    )
}

export default OperePreview

