import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import Gallery from "../../UI/gallery/Gallery";
import cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import icon from '../../../icons/marker-icon.png';
import Informazioni from "../../novita/UI/Informazioni";
import CardImgHeader from "../../novita/UI/CardImgHeader";

import './MuseiDetail.css'

import API from '../../api'
import BackButton from "../../UI/BackButton";
import Spinner from "../../UI/Spinner";
import Reader from "../../novita/eventi/Reader";
import GoToDirection from "../../UI/GoToDirection";
let DefaultIcon = L.icon({
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

const MuseiDetail = (props) => {

    const { t } = useTranslation();

    const params = useParams()
    const [musei, setMusei] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        async function fetchMuseiHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')

                setIsLoading(true);
                let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/musei/${params.museiId}?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image`)

                const dataMuseio = await response.data;
                setError(null)
                const dataMuseioArray = [dataMuseio]


                const transformedMusei = dataMuseioArray.map(museiData => {
                    return{
                        id: museiData.data.id,
                        title: museiData.data.attributes.title,
                        body: museiData.data.attributes.body,
                        date_start: museiData.data.attributes.field_data_inizio,
                        date_end: museiData.data.attributes.field_data_fine,
                        latitude: museiData.data.attributes.field_latitudine,
                        longitude: museiData.data.attributes.field_longitudine,
                        preview: dataMuseio.included[1].attributes.uri.url,
                        galleria: dataMuseio.included,
                        audio: dataMuseio.included,
                        dove: museiData.data.attributes.field_dove,
                        prezzi: museiData.data.attributes.field_prezzi,
                        contatti: museiData.data.attributes.contatti
                    };
                });
                setMusei(transformedMusei);
                setIsLoading(false);
        }
        fetchMuseiHandler()
    }, [params.museiId]);

    return(
        <div>
            <section>
                <BackButton
                link={'/scopri/musei/list'}
                />

                {!isLoading && musei.length > 0 &&
                    <div key={musei.id}>
                        <CardImgHeader
                            className='relative'
                            preview={musei[0].preview}
                            title={musei[0].title}
                            category={t('museo_category')}
                            isLoading={isLoading}
                            data={musei[0]}
                        />

                        <div className='content p-8 lg:px-24 break-words'>
                            <GoToDirection
                                latitude={musei[0].latitude}
                                longitude={musei[0].longitude}
                            />
                            <Reader
                                value={[musei[0].title.replace(/<[^>]*>?/gm, ''), (musei[0].body === null || musei[0].body === undefined) ? '' : musei[0].body.processed.replace(/<[^>]*>?/gm, '')]}
                                body={(musei[0].body === null || musei[0].body === undefined) ? '' : musei[0].body.processed.replace(/<[^>]*>?/gm, '')}
                                audio={musei[0].audio}
                            />
                            <Informazioni
                                dove={musei[0].dove}
                                dateFrom={(musei[0].date_start === null || musei[0].date_start === undefined) ? '' : musei[0].date_start}
                                dateTo={(musei[0].date_end === null || musei[0].date_end === undefined) ? '' : musei[0].date_end}
                                prezzi={musei[0].prezzi}
                            />
                            {/*{(musei[0].body === null || musei[0].body === undefined) ? '' : musei[0].body > 0 &&*/}
                                <div className='description mt-3'>
                                    <span className='font-medium mb-3 block lg:text-xl'>{t('descrizione')}</span>
                                    {(musei[0].body === null || musei[0].body === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: musei[0].body.processed}} />}
                                </div>
                            {/*}*/}
                        </div>
                        <div className='galleria'>
                            <section className="overflow-hidden text-gray-700 museis-container mt-8 mb-9">
                                <div className="px-1 py-2">
                                    <div className="flex -m-1 md:-m-2">
                                        <Gallery
                                            gallery={musei[0].galleria}
                                        >
                                            {props.children}
                                        </Gallery>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {(musei[0].latitude === null || musei[0].latitude === undefined) ? '' : musei[0].latitude > 0 && (musei[0].longitude === null || musei[0].longitude === undefined) ? '' : musei[0].longitude  > 0 &&

                            <MapContainer
                                center={[(musei[0].latitude === null || musei[0].latitude === undefined) ? '' : musei[0].latitude, (musei[0].longitude === null || musei[0].longitude === undefined) ? '' : musei[0].longitude]}
                                zoom={13}
                                scrollWheelZoom={false}
                                className='w-full h-72 lg:h-96 z-0'>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[(musei[0].latitude === null || musei[0].latitude === undefined) ? '' : musei[0].latitude, (musei[0].longitude === null || musei[0].longitude === undefined) ? '' : musei[0].longitude]}>
                                    {/*<Popup>*/}
                                    {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                    {/*</Popup>*/}
                                </Marker>
                            </MapContainer>
                        }
                        {/*<a target='_blank' rel="noreferrer" href={`http://maps.google.com/maps?saddr=50,10&daddr=50,20`}>Test</a>*/}

                    </div>
                }
                {!isLoading && musei.length === 0 && <p>No musei data</p>}
                {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
                {!isLoading && error && <p>{error.message}</p>}
            </section>
        </div>
    );}

export default MuseiDetail;
