import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import cookies from "js-cookie";
// import {useTranslation} from "react-i18next";
import icon from '../icons/marker-icon.png';

import API from '../components/api'
import BackButton from "../components/UI/BackButton";
import CardImgHeader from "../components/novita/UI/CardImgHeader";
import Reader from "../components/novita/eventi/Reader";
import Informazioni from "../components/novita/UI/Informazioni";
import Gallery from "../components/UI/gallery/Gallery";
import Spinner from "../components/UI/Spinner";

let DefaultIcon = L.icon({
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

const LaStoria = () => {

    // const { t } = useTranslation();

    const params = useParams()
    const [laStoria, setLaStoria] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchLaStoriaHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')

            setIsLoading(true);
            let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/pagina_informativa/838dacfb-2eff-444a-8d05-1fb473be928a?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image`)

            const dataLaStoria = await response.data;
            const dataLaStoriaArray = [dataLaStoria]



            const transformedLaStoria = dataLaStoriaArray.map(laStoriaData => {
                return{
                    id: laStoriaData.data.id,
                    title: laStoriaData.data.attributes.title,
                    body: laStoriaData.data.attributes.body,
                    date_start: laStoriaData.data.attributes.field_data_inizio,
                    date_end: laStoriaData.data.attributes.field_data_fine,
                    latitude: laStoriaData.data.attributes.field_latitudine,
                    longitude: laStoriaData.data.attributes.field_longitudine,
                    preview: dataLaStoria.included[1].attributes.uri.url,
                    galleria: dataLaStoria.included,
                    dove: laStoriaData.data.attributes.field_dove,
                    prezzi: laStoriaData.data.attributes.field_prezzi,
                    contatti: laStoriaData.data.attributes.contatti,
                };
            });

            // setLaStoria((prevState => {
            //     return [prevState, ...transformedLaStoria]
            // }));
            setLaStoria(transformedLaStoria);
            setIsLoading(false);


        }
        fetchLaStoriaHandler()


    }, [params.laStoriaId]);
    console.log(laStoria)

    return(

        <div>
            <section>
                <BackButton
                    link={'/novita'}
                />
                {!isLoading && laStoria.length > 0 &&
                    <div key={laStoria.id}>
                        <CardImgHeader
                            className='relative'
                            preview={laStoria[0].preview}
                            title={laStoria[0].title}
                            // category={t('ilcretto_category')}
                            isLoading={isLoading}
                            data={laStoria[0]}
                        />

                        <div className='content p-8 lg:px-24 break-words'>
                            <Reader
                                value={[laStoria[0].title.replace(/<[^>]*>?/gm, ''), (laStoria[0].body === null || laStoria[0].body === undefined) ? '' : laStoria[0].body.processed.replace(/<[^>]*>?/gm, '')]}
                            />
                            <Informazioni
                                dove={laStoria[0].dove}
                                dateFrom={(laStoria[0].date_start === null || laStoria[0].date_start === undefined) ? '' : laStoria[0].date_start}
                                dateTo={(laStoria[0].date_end === null || laStoria[0].date_end === undefined) ? '' : laStoria[0].date_end}
                                prezzi={laStoria[0].prezzi}
                            />
                            {laStoria[0].body &&
                                <div className='description mt-3'>
                                    <span className='font-medium mb-3 block'>Descrizione</span>
                                    {(laStoria[0].body === null || laStoria[0].body === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: laStoria[0].body.processed}} />}


                                </div>
                            }

                        </div>
                        <div className='galleria'>
                            {/*<section className="overflow-hidden text-gray-700 laStorias-container -ml-28 mt-8 mb-9">*/}
                            <section className="overflow-hidden text-gray-700 laStorias-container mt-8 mb-9">
                                {/*<div className="container px-1 py-2 lg:pt-12 lg:px-32 ">*/}
                                <div className="px-1 py-2 w-full ">
                                    <div className="flex -m-1 md:-m-2">
                                        <Gallery
                                            gallery={laStoria[0].galleria}
                                        />
                                        {/*{props.children}*/}
                                        {/*</Galleria>*/}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {(laStoria[0].latitude === null || laStoria[0].latitude === undefined) ? '' : laStoria[0].latitude > 0 && (laStoria[0].longitude === null || laStoria[0].longitude === undefined) ? '' : laStoria[0].longitude  > 0 &&

                            <MapContainer
                                center={[(laStoria[0].latitude === null || laStoria[0].latitude === undefined) ? '' : laStoria[0].latitude, (laStoria[0].longitude === null || laStoria[0].longitude === undefined) ? '' : laStoria[0].longitude]}
                                zoom={13}
                                scrollWheelZoom={false}
                                className='w-full h-72 z-0'>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[(laStoria[0].latitude === null || laStoria[0].latitude === undefined) ? '' : laStoria[0].latitude, (laStoria[0].longitude === null || laStoria[0].longitude === undefined) ? '' : laStoria[0].longitude]}>
                                    <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        }
                    </div>
                }
                {!isLoading && laStoria.length === 0 && <p>No laStoria data</p>}
                {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            </section>
        </div>
    );}


export default LaStoria;
