import { Menu, Transition  } from '@headlessui/react'

import i18next from "i18next";
import {useTranslation} from "react-i18next";
import 'flag-icon-css/css/flag-icons.min.css'

const reloadPage = () => {
    window.location.href = '/'
}

const languages = [
    {
        code: 'it',
        name: 'Italiano',
        country_code: 'it'
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb'
    }
]


const LanguageBox = () => {

        const { t } = useTranslation();




        return (
                        <div className='px-1 py-1 z-9999 fixed bottom-10 w-full '>
                            <div className='text-center'>

                            { languages.map(({code, name, country_code}) => (
                                        <button

                                            className=' m-2 inline-flex bg-white text-newGrey font-medium rounded-md bg-opacity-100 p-4 text-center text-sm font-medium text-white hover:bg-opacity-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                                            onClick={() => {
                                                i18next.changeLanguage(code);
                                                reloadPage()
                                            }
                                            }
                                        >
                                            <span
                                                className={`flag-icon flag-icon-${country_code} mr-2 mt-1`}
                                            >

                                            </span>
                                            {name}
                                        </button>
                            ))}
                            </div>

                        </div>
        )


}

export default LanguageBox
