import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cookies from "js-cookie";

import API from '../../api'


const OperePreview = (props) =>{
    const { t } = useTranslation();
    const [opereImage, setOpereImage] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    console.log(props)


    useEffect(() => {
        async function fetchOpereImageHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')
            setIsLoading(true);
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/media/image/${props.previewId}?include=field_media_image`)
            const dataOpereImage = await response.data;
            if(dataOpereImage){
                const transformedOpereImage = dataOpereImage.included.map(opereImageData => {
                    return{
                        previewImage: opereImageData.attributes.uri.url
                    };
                });
                setOpereImage([...transformedOpereImage]);
                setIsLoading(false);
            }
            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(fetchOpereImageHandler)
    }, [props.previewId]);
    return(
        <div id={props.id} className='opere-item'>
            <Link to={`/percorsi/opere/${props.id}`} className='w-full text-greenText font-medium'>
                <div className='px-8 pt-4 mt-4'>
                    <span className='block uppercase text-xs'>
                        {t('opere_category')}
                    </span>
                    <h2 className='font-light text-greenText'>
                        {props.title}
                    </h2>
                </div>
                {!isLoading && opereImage.length > 0 &&
                    <div
                        className='mt-4 mb-4 relative flex-none sm:self-start lg:self-auto xl:self-start h-32 overflow-hidden shadow-lg cursor-pointer'
                    >
                        <img className="object-cover w-full h-48"
                             src={`https://gibellina.bbsitalia.com/${opereImage[0].previewImage}`}
                             alt="Flower and sky"
                        />
                    </div>
                }
                <div className='px-8 pb-5'>
                    {/*<span className='block' dangerouslySetInnerHTML={{ __html: props.body.processed}} />*/}
                    {/*    /!*<a href={https://www.google.com/maps/search/?api=1&query=${OperePreviewFiltered.latitude}%2C${OperePreviewFiltered.longitude}}>*!/*/}
                    {/*        <a href={https://www.google.com/maps/search/?api=1&query=BBS+tecnologia+di=buon+senso}>*/}
                    {/*        Open in maps*/}
                    {/*    </a>*/}
                    {(props.body === null || props.body === undefined) ? '' :                            <div dangerouslySetInnerHTML={{ __html: props.body.summary.substr(0, Math.min(props.body.summary.substr(0, 60).length, props.body.summary.substr(0, 60).lastIndexOf(" "))) + '...'}} />
                    }
                        <span className='text-greenText font-medium bock' >{t('read_more')}...</span>
                </div>
            </Link>
        </div>
    )
}
export default OperePreview
