import DoveDormireList from "./DoveDormireList";
import React, {useEffect, useState} from "react";
import cookies from "js-cookie";

import API from '../../api'
import Spinner from "../../UI/Spinner";


const DoveDormire = () =>{
    const [doveDormire, setDoveDormire] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchDoveDormireHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')
            setIsLoading(true);
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/dove_dormire?include=field_immagine_anteprima.field_media_image`)
            const dataDoveDormire = await response.data;
            console.log(dataDoveDormire)
            if(dataDoveDormire){
                const transformedDoveDormire = dataDoveDormire.data.map(doveDormireData => {
                    return{
                        id: doveDormireData.id,
                        title: doveDormireData.attributes.title,
                        body: doveDormireData.attributes.body,
                        latitude: doveDormireData.attributes.field_latitudine,
                        longitude: doveDormireData.attributes.field_longitudine,
                        previewId: doveDormireData.relationships.field_immagine_anteprima.data.id,
                        tag: doveDormireData.attributes.field_tag_testo
                    };
                });
                setDoveDormire(transformedDoveDormire);
                setIsLoading(false);
            }
            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(fetchDoveDormireHandler)
    }, []);
    return(
        <section className='w-full lg:w-10/12'>
            {!isLoading && doveDormire.length > 0 &&
                <div className='doveDormire'>
                    <DoveDormireList
                        doveDormire={doveDormire}
                    />
                </div>
            }
            {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            {!isLoading && doveDormire.length === 0 && <p>No doveDormire data</p>}
        </section>
    )
}

export default DoveDormire;
