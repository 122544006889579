import ServiziList from "./ServiziList";
import React, {useEffect, useState} from "react";
import cookies from "js-cookie";

import API from '../../api'
import Spinner from "../../UI/Spinner";

const currentLanguageCode = localStorage.getItem('i18nextLng')

const Servizi = () =>{
    const [servizi, setServizi] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchServiziHandler() {
            setIsLoading(true);
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/servizi?include=field_immagine_anteprima.field_media_image`)
            const dataServizi = await response.data;
            // console.log(dataServizi)
            if(dataServizi){
                const transformedServizi = dataServizi.data.map(serviziData => {
                    return{
                        id: serviziData.id,
                        title: serviziData.attributes.title,
                        body: serviziData.attributes.body,
                        latitude: serviziData.attributes.field_latitudine,
                        longitude: serviziData.attributes.field_longitudine,
                        previewId: serviziData.relationships.field_immagine_anteprima.data.id
                    };
                });
                setServizi(transformedServizi);
                setIsLoading(false);
            }
            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(fetchServiziHandler)
    }, []);
    return(
        <section className='w-full lg:w-10/12'>
            {!isLoading && servizi.length > 0 &&
                <div className='servizi'>
                    <ServiziList
                        servizi={servizi}
                    />
                </div>
            }
            {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            {!isLoading && servizi.length === 0 && <p>No servizi data</p>}
        </section>
    )
}

export default Servizi;
