import API from "../../api";
import {useEffect, useState} from "react";

const ServiziDetailTagList = (props) => {

    const [tagDataPrimaria, setTagDataPrimaria] = useState([]);

    useEffect(() => {

        async function getTagPrimaria() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')
            // console.log(servizi[0].taxonomy_primaria[0].meta.drupal_internal__target_id)
            let newResponse = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonget/taxonomy-term/${props.tag.meta.drupal_internal__target_id}`)
            const dataServizio2 = await newResponse.data
            setTagDataPrimaria([tagDataPrimaria, dataServizio2[0].name])
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(getTagPrimaria)


    }, [])
    return(
        <div className={'inline-block border p-2 rounded-full border border-red-700 mr-2 mb-2'}>
            {tagDataPrimaria}
        </div>
    );}
export default ServiziDetailTagList;
