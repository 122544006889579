// @src/components/Modal.jsx

import React from "react";
import styles from "./Modal.module.css";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Modal = ({ setIsOpen }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
            <div className={styles.centered}>
                <div className={styles.modal}>
                    <div className={styles.modalHeader}>
                        <h5 className={styles.heading}>{t("opere_popup_title")}</h5>
                    </div>
                    <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
                        X
                    </button>
                    <div className={styles.modalContent}>
                        {t("opere_popup_desc")}
                    </div>
                    <div className={styles.modalActions}>
                        <div className={styles.actionsContainer}>
                            <NavLink
                                to={`/percorsi`}
                            >
                            <button className={styles.deleteBtn} onClick={() => setIsOpen(false)}>
                                {t("opere_popup_button")}
                            </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
