import React from 'react';

import "./Date.css";

const DateEvent = (props) => {
    const dateFromStr = props.dateFrom;
    const dateToStr = props.dateFrom;

    let newDateFrom = new Date(dateFromStr)
    let newDateTo = new Date(dateToStr)

    const monthFrom = newDateFrom.toLocaleString("it-IT", { month: "long" });
    const dayFrom = newDateFrom.toLocaleString("it-IT", { day: "2-digit" });
    const yearFrom = newDateFrom.getFullYear();
    const monthTo = newDateTo.toLocaleString("it-IT", { month: "long" });
    const dayTo = newDateTo.toLocaleString("it-IT", { day: "2-digit" });
    const yearTo = newDateTo.getFullYear();

  return (
      <div className="date">
          <div className="date-from">
              <div className="date__month inline-block">{monthFrom}</div>
              <div className="date__year inline-block">{yearFrom}</div>
              <div className="date__day inline-block">{dayFrom}</div>
          </div>
          <div className="date-to">
              <div className="date__month inline-block">{monthTo}</div>
              <div className="date__year inline-block">{dayTo}</div>
              <div className="date__day inline-block">{yearTo}</div>
          </div>
      </div>
  );
}

export default DateEvent;
