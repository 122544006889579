const ListButtonIcon = () => {
    return(
        <svg  className='lg:w-8 lg:h-8 lg:text-center lg:m-auto lg:mb-1' id="Icon_ionic-ios-list" data-name="Icon ionic-ios-list" xmlns="http://www.w3.org/2000/svg" width="15.579" height="12.333" viewBox="0 0 15.579 12.333">
            <path id="Tracciato_10567" data-name="Tracciato 10567" d="M6.772,17.167a1.136,1.136,0,1,1-1.136-1.136A1.136,1.136,0,0,1,6.772,17.167Z" transform="translate(-4.5 -11)" fill="#fff"/>
            <path id="Tracciato_10568" data-name="Tracciato 10568" d="M6.772,8.448A1.136,1.136,0,1,1,5.636,7.313,1.136,1.136,0,0,1,6.772,8.448Z" transform="translate(-4.5 -7.313)" fill="#fff"/>
            <path id="Tracciato_10569" data-name="Tracciato 10569" d="M6.772,25.886A1.136,1.136,0,1,1,5.636,24.75,1.136,1.136,0,0,1,6.772,25.886Z" transform="translate(-4.5 -14.688)" fill="#fff"/>
            <path id="Tracciato_10570" data-name="Tracciato 10570" d="M22.746,16.875H12.989a.649.649,0,0,0,0,1.3h9.757a.649.649,0,0,0,0-1.3Z" transform="translate(-7.816 -11.357)" fill="#fff"/>
            <path id="Tracciato_10571" data-name="Tracciato 10571" d="M22.746,25.594H12.989a.649.649,0,1,0,0,1.3h9.757a.649.649,0,0,0,0-1.3Z" transform="translate(-7.816 -15.045)" fill="#fff"/>
            <path id="Tracciato_10572" data-name="Tracciato 10572" d="M12.989,9.455h9.757a.649.649,0,0,0,0-1.3H12.989a.649.649,0,1,0,0,1.3Z" transform="translate(-7.816 -7.669)" fill="#fff"/>
        </svg>
    );
}

export default ListButtonIcon
