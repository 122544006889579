import MapButton from "../../UI/MapButton";
import DoveDormireItem from "./DoveDormireItem";
import React from "react";
import {useTranslation} from "react-i18next";

const DoveDormireListContainer = (props) => {
    const { t } = useTranslation();

    return(
        <div className='doveDormire-list px-8 mt-3' >
            <div className='block w-100 relative mb-5'>
                <h2 className='pb-4 text-greenText font-normal inline-block text-left pr-20 mt-2' style={{lineHeight: "34px"}}>{t('section_doveDormire_title')}</h2>
                <MapButton
                    url='/scopri/dove-dormire'
                />
            </div>
            <div className='doveDormire-items w-100 block'>
                { props.items.doveDormire.map((doveDormireList, i) => (
                    <DoveDormireItem
                        key={doveDormireList.id}
                        url={`/scopri/dove-dormire/list/${doveDormireList.id}`}
                        title={doveDormireList.title}
                        body={doveDormireList.body}
                        previewId={doveDormireList.previewId}
                        tag={doveDormireList.tag}
                    />
                ))}
            </div>
        </div>
    )
}

export default DoveDormireListContainer