import React from "react";
import NewsItem from "./NewsItem";

const NewsList = (props) => {
    return (
        <section className="overflow-hidden text-gray-700 events-container px-8">
            { props.notizie.map((newsList) => (
                <NewsItem
                    key={newsList.id}
                    url={`/novita/notizie/${newsList.id}`}
                    title={newsList.title}
                    body={newsList.body}
                    previewId={newsList.previewId}
                />
            ))}
        </section>
    );
}

export default NewsList;
