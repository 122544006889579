import MapButton from "../../UI/MapButton";
import DoveMangiareItem from "./DoveMangiareItem";
import React from "react";
import {useTranslation} from "react-i18next";

const DoveMangiareListContainer = (props) => {
    const { t } = useTranslation();

    return(
        <div className='doveMangiare-list px-8 mt-3' >
            <div className='block w-100 relative mb-5'>
                <h2 className='pb-4 text-greenText font-normal inline-block text-left pr-20 mt-2' style={{lineHeight: "34px"}}>{t('section_doveMangiare_title')}</h2>
                <MapButton
                    url='/scopri/dove-mangiare'
                />
            </div>
            <div className='doveMangiare-items w-100 block'>
                { props.items.doveMangiare.map((doveMangiareList, i) => (
                    <DoveMangiareItem
                        key={doveMangiareList.id}
                        url={`/scopri/dove-mangiare/list/${doveMangiareList.id}`}
                        title={doveMangiareList.title}
                        body={doveMangiareList.body}
                        previewId={doveMangiareList.previewId}
                        tag={doveMangiareList.tag}

                    />
                ))}
            </div>
        </div>
    )
}

export default DoveMangiareListContainer