import DoveMangiareList from "./DoveMangiareList";
import React, {useEffect, useState} from "react";
import cookies from "js-cookie";

import API from '../../api'
import Spinner from "../../UI/Spinner";

const currentLanguageCode = localStorage.getItem('i18nextLng')

const DoveMangiare = () =>{
    const [doveMangiare, setDoveMangiare] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchDoveMangiareHandler() {
            setIsLoading(true);
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/dove_mangiare?include=field_immagine_anteprima.field_media_image`)
            const dataDoveMangiare = await response.data;
            console.log(dataDoveMangiare)
            if(dataDoveMangiare){
                const transformedDoveMangiare = dataDoveMangiare.data.map(doveMangiareData => {
                    return{
                        id: doveMangiareData.id,
                        title: doveMangiareData.attributes.title,
                        body: doveMangiareData.attributes.body,
                        latitude: doveMangiareData.attributes.field_latitudine,
                        longitude: doveMangiareData.attributes.field_longitudine,
                        previewId: doveMangiareData.relationships.field_immagine_anteprima.data.id,
                        tag: doveMangiareData.attributes.field_tag_testo

                    };
                });
                setDoveMangiare(transformedDoveMangiare);
                setIsLoading(false);
            }
            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(fetchDoveMangiareHandler)
    }, []);
    return(
        <section className='w-full lg:w-10/12'>
            {!isLoading && doveMangiare.length > 0 &&
                <div className='doveMangiare'>
                    <DoveMangiareList
                        doveMangiare={doveMangiare}
                    />
                </div>
            }
            {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            {!isLoading && doveMangiare.length === 0 && <p>No doveMangiare data</p>}
        </section>
    )
}

export default DoveMangiare;
