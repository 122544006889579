// import Opere2Item from "./Opere2Item";
import React from "react";
import {Route, Routes} from "react-router-dom";
// import {Refreshable} from 'react-router-refreshable';
import Maps from "./Maps";
// import {useTranslation} from "react-i18next";
// import MapButton from "../../UI/MapButton";
import DoveMangiareListContainer from "./DoveMangiareListContainer";


const DoveMangiareList = (props) =>{

    // const { t } = useTranslation();

    return(
        <section className="overflow-hidden text-gray-700 events-container">
            <Routes>
                <Route path="list" exact={true} element={
                    <DoveMangiareListContainer
                        items={props}
                    />
                }>
                </Route>
                <Route path="maps" exact={true} element={
                    <Maps
                        className='h-screen'
                        doveMangiare={props.doveMangiare}
                    />
                }/>
            </Routes>
        </section>
    )
}

export default DoveMangiareList;
