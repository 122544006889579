// import DoveDormireItem from "./DoveDormireItem";
import React from "react";
import {Route, Routes} from "react-router-dom";
// import {Refreshable} from 'react-router-refreshable';
import Maps from "./Maps";
// import {useTranslation} from "react-i18next";
// import MapButton from "../../UI/MapButton";
import DoveDormireListContainer from "./DoveDormireListContainer";


const DoveDormireList = (props) =>{

    // const { t } = useTranslation();

    return(
        <section className="overflow-hidden text-gray-700 events-container">
            <Routes>
                <Route path="list" exact={true} element={
                    <DoveDormireListContainer
                        items={props}
                    />
                }>
                </Route>
                <Route path="maps" exact={true} element={
                    <Maps
                        className='h-screen'
                        doveDormire={props.doveDormire}
                    />
                }/>
            </Routes>
        </section>
    )
}

export default DoveDormireList;
